import * as React from 'react';
// Icons
import { ReactComponent as IconAdd } from 'assets/svg/add.svg';

// Components
import DashboardCard from 'components/cards/dashboard';
import Listing from 'components/cards/listing';
import { listingContext, ListingSortOption, SortDirection } from 'components/cards/listing-context';

import api from 'api';
import useRequest from 'api/use-request';
import { useState, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const DashboardsIndexPage: React.FunctionComponent = () => {
  let location = useLocation();
  let searchArgs = queryString.parse(location.search, { parseNumbers: true });

  let [selection, setSelection] = useState<string[]>([]);

  let dashboards = useRequest(api.dashboards.getDashboards(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: 'order', title: 'Order, ascending', direction: SortDirection.Ascending },
    { key: 'order', title: 'Order, descending', direction: SortDirection.Descending },
    { key: 'name', title: 'Name, ascending', direction: SortDirection.Ascending },
    { key: 'name', title: 'Name, descending', direction: SortDirection.Descending },
    { key: 'created_at', title: 'Created At, ascending', direction: SortDirection.Ascending },
    { key: 'created_at', title: 'Created At, descending', direction: SortDirection.Descending },
    { key: 'type', title: 'Type, ascending', direction: SortDirection.Ascending },
    { key: 'type', title: 'Type, descending', direction: SortDirection.Descending },
  ];

  function deleteDashboards(ids: string[]) {
    try {
      ids.forEach((id) => api.dashboards.deleteDashboard({ id }))
    } catch (error) {
      // TODO: handle error
    }
    dashboards.mutate();
  }

  function toggleSelectDashboards(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (dashboards.data && dashboards.data?.data.length > 0) {
      dashboards.data?.data.forEach((dashboard) => {
        const index = selection.indexOf(dashboard.id);
        if (shouldSelect && index < 0) {
          selection.push(dashboard.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  return (
    <div className="o-container-fluid o-container--full">
      <div className="c-page-title">
        <h2 className="c-title--large">All Dashboards</h2>
        <div className="c-button c-button--stroke c-button--primary c-button--icon u-hidden@md"><IconAdd className="o-svg-icon" /></div>
        <div className="c-button c-button--stroke c-button--primary u-hidden u-block@md"><IconAdd className="o-svg-icon" /><span>Add new dashboard</span></div>
      </div>
      <listingContext.Provider value={{ ...dashboards, selection, sort_options }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Listing
            deleteHandler={deleteDashboards}
            selectionHandler={toggleSelectDashboards}
            selectAllHandler={selectAll}
          >
            {dashboards.data?.data.map((dashboard) =>
              <DashboardCard
                deleteHandler={deleteDashboards}
                selectHandler={toggleSelectDashboards}
                key={dashboard.id}
                dashboard={dashboard}
              />
            )}
          </Listing>
        </Suspense>
      </listingContext.Provider>
    </div>
  );
};

export default DashboardsIndexPage;
