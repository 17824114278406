import { Instance, types } from 'mobx-state-tree';

export const AppError = types.model({
  type: types.enumeration(['network', 'permission', 'unhandled']),
  message: types.string,
  cta: types.maybe(types.string),
})

interface IAppError extends Instance<typeof AppError> {}

export const Errors = types
  .model({
    app: types.array(AppError),
  })
  .views((self) => ({
    get hasError() {
      return true;
    }
  }))
  .actions((self) => ({
    presentError: function(error: IAppError) {
      self.app.push(error);
    },
    presentNetworkError: function(message: string, cta?: string) {
      if (!self.app.find(({ type }) => type === 'network')) {
        self.app.push({
          type: 'network',
          message,
          cta,
        });
      }
    },
    presentUnhandledError: function(message: string, cta?: string) {
      if (!self.app.find(({ type }) => type === 'unhandled')) {
        self.app.push({
          type: 'unhandled',
          message,
          cta,
        });
      }
    },
    clearNetworkError: function() {
      const index = self.app.findIndex(error => error.type === 'network');
      if (index !== -1) {
        self.app.splice(index, 1);
      }
    },
  }))

export default Errors;
