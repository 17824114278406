import { CubeWidgetDataSource } from "data/utils";

export const getDataSourceAsTabs = (
  dataSource: CubeWidgetDataSource | CubeWidgetDataSource[],
  tabTitles?: string[]
): [string, string][] | undefined => {
  if (Array.isArray(dataSource)) {
    return dataSource.map((ds, index) => {
      const title = tabTitles ? tabTitles[index] : ds.title;
      return [ds.id, title]
    });
  }

  return undefined;
};
