import { ResultSet } from "@cubejs-client/core";

export const handle = (
  resultSet: ResultSet<any> | null,
  isLoading: boolean,
) => {
  if (isLoading && !resultSet) {
    throw new Promise(() => {});
  }
};
