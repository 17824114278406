import React from 'react';
import { RootStoreContext } from 'store';

interface IErrorBoundaryProps {

}

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  static contextType = RootStoreContext;
  // TODO: Typescript issues with Store Context in class components
  // declare context: React.ContextType<typeof RootStoreContext>;

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: Object) {
    console.log("Unhandled Exception Caught", error, errorInfo);
    this.context.errors.presentUnhandledError(error.message);

    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}
