import * as React from "react";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import config from "config";
import {
  CubeDashboardContextProvider,
} from "components/cube/contexts/dashboard-context";
import { useEffect, useMemo, useState } from "react";
import useRequest from "api/use-request";
import api from "api";
import { useNavigate, useParams } from "react-router-dom";
import DashboardTitle from "components/layout/dashboard-title";
import LoadingSkeleton from "components/cube/elements/skeletons/loading";
import ClientAdReportingDashboard from "components/cube/dashboards/client-ad-reporting-dashboard";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export interface ICubeDashboardPageProps {
  client?: string;
}

export default function CubeDashboardPage({ client }: ICubeDashboardPageProps) {
  const [token, setToken] = useState<string>("");
  const navigate = useNavigate();

  let { id } = useParams() as {
    id: string;
  };

  const tokenResponse = useRequest(api.cube.getDashboardToken({ dashboard_id: id }));

  // Revalidate Cube tokens every 30 minutes
  useEffect(() => {
    const timer = setInterval(() => {
      tokenResponse.mutate();
    }, 1800000);
    return () => clearInterval(timer);
  }, [tokenResponse]);

  useEffect(() => {
    setToken("");
  }, [id]);

  // Avoid Cube query 403 errors when switching between client databases
  const cubejsApi = useMemo(() => {
    if (token !== "") {
      return cubejs(token, { apiUrl: config.CUBEJS_API_URL });
    } else {
      return cubejs(Promise.reject, { apiUrl: config.CUBEJS_API_URL });
    }
  }, [token]);

  if (tokenResponse.data && tokenResponse.data.token !== token) {
    setToken(tokenResponse.data.token);
  } else if (token === "" || (tokenResponse.isValidating && !tokenResponse.data?.token)) {
    if (tokenResponse.error) {
      if (tokenResponse.error.response?.status === 404) {
        navigate('/404');
      } else if (tokenResponse.error.response?.status === 401) {
        navigate('/401');
      } else {
        console.log("token response status", tokenResponse.error);
        // throw new Error(tokenResponse.error.message);
      }
    }

    return (
      <section className="c-block c-block--spacing-b-small">
        <div className="o-container-fluid o-container--full">
          <DashboardTitle />
          <LoadingSkeleton />
        </div>
      </section>
    );
  }

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <CubeDashboardContextProvider>
        <section className="c-block c-block--spacing-b-small">
          <div className="o-container-fluid o-container--full">
            <DashboardTitle />
            <React.Suspense fallback={<LoadingSkeleton />}>
              <ClientAdReportingDashboard client={client} />
            </React.Suspense>
          </div>
        </section>
      </CubeDashboardContextProvider>
    </CubeProvider>
  );
}

