import { createContext } from 'react';
import { Links, Meta } from 'api/endpoints/common';

export enum ListingType {
  'list' = 'list',
  'grid' = 'grid',
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc'
}

export type ListingSortOption = {
  key: string,
  title: string,
  direction: SortDirection
}

export type IListingContext = {
  data: {
    data: unknown,
    links: Links,
    meta: Meta,
  } | undefined | null,
  type: ListingType,
  selection: any[],
  sort_options: ListingSortOption[]
}

export const listingContext = createContext<Partial<IListingContext>>({} as IListingContext);
