import { isArray, isObject, mapValues, isPlainObject, isNull, isString } from 'lodash';

/**
 * Map php values play nicely with validation
 * @param value to map
 */
export function phpToUndefined(value: unknown): any {
  if (isArray(value)) {
    return value.map(phpToUndefined);
  }

  if (isObject(value) && isPlainObject(value)) {
    return mapValues(value, phpToUndefined);
  }

  if (isNull(value)) {
    return undefined;
  }

  if (isString(value) && value === '') {
    return undefined;
  }

  return value;
};

export function phpToDate(value: unknown): any {
  const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

  if (isArray(value)) {
    return value.map(phpToUndefined);
  }

  if (isObject(value) && isPlainObject(value)) {
    return mapValues(value, phpToUndefined);
  }

  if (isString(value) && dateRegex.test(value)) {
    return new Date(value);
  }
}
