import HttpClient from 'api/http-client';
import * as z from 'zod';

export const FileUploadResponseSchema = z.object({
  file_path: z.string().nonempty(),
  file_url: z.string().nonempty()
});
export type FileUploadResponseObject = z.TypeOf<typeof FileUploadResponseSchema>

export function uploadFile(data: FormData, directory: string) {
  return new HttpClient().request<FileUploadResponseObject>({
    method: 'post',
    url: '/assetUpload?directory=' + directory,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
