import * as React from 'react';
import { useContext } from 'react';
import { sizedGroupLogoImageURL } from 'utils/imgix-helpers';
import { PageContext } from './page-context';

interface IPageTitleProps {
}

const PageTitle: React.FunctionComponent<IPageTitleProps> = (props) => {
  const page = useContext(PageContext);

  return (
    page.logoURL ? (
      <img src={sizedGroupLogoImageURL(page.logoURL)} />
    ) : (
      <p>{page.title}</p>
    )
  );
};

export default PageTitle;
