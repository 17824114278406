import { formatLocalizedInt } from "data/formatters";
import { FormatConfig, LabelsConfig, TCubeQuery } from "data/types";

export const title: string = "Total Conversions";

export const query: TCubeQuery = {
  measures: ["campaign_report.conversions"],
  timeDimensions: [
    {
      dimension: "campaign_report.date",
      granularity: "month",
    },
  ]
};

export const filteredBy = ["campaign_report.date", "campaign_report.platform"];

export const formatConfig: FormatConfig = {
  any: {
    "campaign_report.conversions": (value) => formatLocalizedInt(value),
  },
};

export const labelsConfig: LabelsConfig = {
  "campaign_report.conversions": "Conversions",
};
