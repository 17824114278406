import { PivotConfig } from "@cubejs-client/core";
import { FormatConfig, LabelsConfig, SubtotalConfig, TCubeQuery } from "data/types";
import { formatCurrencyUSD, formatLocalizedInt, formatPercentage, formatStartCase } from "data/formatters";
import utils from "components/cube/utils";

export const title: string = "Platform Performance Breakdown";

export const query: TCubeQuery = {
  measures: [
    "campaign_report.impressions",
    "campaign_report.clicks",
    "campaign_report.conversions",
    "campaign_report.spend",
    "campaign_report.cpa",
    "campaign_report.cvr",
    "campaign_report.cpc",
    "campaign_report.ctr",
  ],
  timeDimensions: [
    {
      dimension: "campaign_report.date",
    },
  ],
  dimensions: ["campaign_report.platform"],
  limit: 25,
};

export const pivotConfig: PivotConfig = {
  x: ["campaign_report.platform"],
  y: ["measures"],
  fillMissingDates: true,
};

export const formatConfig: FormatConfig = {
  any: {
    "campaign_report.impressions": (value) => formatLocalizedInt(value),
    "campaign_report.clicks": (value) => formatLocalizedInt(value),
    "campaign_report.cpa": (value) => formatCurrencyUSD(value),
    "campaign_report.cvr": (value) => formatPercentage(value),
    "campaign_report.cpc": (value) => formatCurrencyUSD(value),
    "campaign_report.ctr": (value) => formatPercentage(value),
    "campaign_report.spend": (value) => formatCurrencyUSD(value),
    "campaign_report.conversions": (value) => formatLocalizedInt(value),
    "campaign_report.platform": (value) => formatStartCase(value),
  },
};

export const subtotalConfig: SubtotalConfig = {
  "campaign_report.impressions": (rows) =>
    formatLocalizedInt(utils.table.calculateFieldSubtotal(rows, "campaign_report.impressions")),
  "campaign_report.clicks": (rows) =>
    formatLocalizedInt(utils.table.calculateFieldSubtotal(rows, "campaign_report.clicks")),
  "campaign_report.conversions": (rows) =>
    formatLocalizedInt(utils.table.calculateFieldSubtotal(rows, "campaign_report.conversions")),
  "campaign_report.spend": (rows) =>
    formatCurrencyUSD(utils.table.calculateFieldSubtotal(rows, "campaign_report.spend")),
  "campaign_report.cpa": (rows) => {
    const totalSpend = utils.table.calculateFieldSubtotal(rows, "campaign_report.spend");
    const totalConversions = utils.table.calculateFieldSubtotal(
      rows,
      "campaign_report.conversions"
    );
    const cpa = totalConversions !== 0 ? totalSpend / totalConversions : 0;
    return formatCurrencyUSD(cpa);
  },
  "campaign_report.cvr": (rows) => {
    const totalConversions = utils.table.calculateFieldSubtotal(rows, "campaign_report.conversions");
    const totalClicks = utils.table.calculateFieldSubtotal(rows, "campaign_report.clicks");
    const cvr = (totalClicks !== 0) ? (totalConversions / totalClicks) * 100 : 0;
    return formatPercentage(cvr);
  },
  "campaign_report.cpc": (rows) => {
    const totalSpend = utils.table.calculateFieldSubtotal(rows, "campaign_report.spend");
    const totalClicks = utils.table.calculateFieldSubtotal(rows, "campaign_report.clicks");
    const cpc = (totalClicks !== 0) ? totalSpend / totalClicks : 0;
    return formatCurrencyUSD(cpc);
  },
  "campaign_report.ctr": (rows) => {
    const totalClicks = utils.table.calculateFieldSubtotal(rows, "campaign_report.clicks");
    const totalImpressions = utils.table.calculateFieldSubtotal(rows, "campaign_report.impressions");
    const ctr = (totalImpressions !== 0) ? (totalClicks / totalImpressions) * 100 : 0;
    return formatPercentage(ctr);
  },
};

export const labelsConfig: LabelsConfig = {
  "campaign_report.spend": "Cost",
};

export const filteredBy = ["campaign_report.date", "campaign_report.platform"];
