import { TableColumn } from "@cubejs-client/core";
import { Row, RowData } from "@tanstack/react-table";

type RowValue = string | number | boolean;
type PivotedRow = { [key: string]: RowValue };
type PivotedRows = Array<PivotedRow>;

export const getFieldColumnId = (field: string) => field.replaceAll(".", "//");

export const getFieldForColumnId = (columnId: string) => columnId.replaceAll("//", ".");

export const calculateFieldSubtotal = <TData extends RowData>(rows: Row<TData>[], field: string) => {
  return Number(rows.reduce((total, row) => total + Number(row.getValue(getFieldColumnId(field))), 0).toFixed(2))
}

export const formatTableData = (columns: TableColumn[], data: PivotedRows) => {
  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {} as { [key: string]: TableColumn });

  return data.map((row) => format(row, typeByIndex));
};

const flatten = (columns: TableColumn[]): TableColumn[] => {
  return columns.reduce((memo: TableColumn[], column: TableColumn) => {
    return column.children ? [...memo, ...flatten(column.children)] : [...memo, column];
  }, [] as TableColumn[]);
};

const format = (row: PivotedRow, typeByIndex: { [key: string]: TableColumn }) => {
  return Object.fromEntries(
    Object.entries(row).map(([dataIndex, value]) => {
      return [getFieldColumnId(dataIndex), formatValue(value, typeByIndex[dataIndex])];
    })
  );
};

const formatValue = (value: RowValue, column: TableColumn) => {
  if (value === undefined) {
    return value;
  }

  if (column.type === "boolean") {
    if (typeof value === "boolean") {
      return value.toString();
    } else if (typeof value === "number") {
      return Boolean(value).toString();
    }

    return value;
  }

  if (column.type === "number" && column.format === "percent") {
    return [parseFloat(String(value)).toFixed(2), "%"].join("");
  }

  if (!value) {
    return "0";
  }

  return value.toString();
};
