import { formatBudget, formatCurrencyUSD } from "data/formatters";
import { FormatConfig, MappableFilterList, TCubeQuery } from "data/types";

export const title: string = "Total Cost";

export const query: TCubeQuery =
  {
    measures: ["campaign_report.spend", "campaign_spend_budget.remaining_budget"],
    timeDimensions: [
      {
        dimension: "campaign_report.date",
        granularity: "month",
      },
    ]
  };

export const filteredBy: MappableFilterList = ["campaign_report.date", "campaign_report.platform"];
// export const filteredBy: MappableFilterList = [["campaign_report.date", "campaign_spend_budget.date"], ["campaign_report.platform", "campaign_spend_budget.platform"]];

export const formatConfig: FormatConfig = {
  any: {
    "campaign_report.spend": (value: number) => formatCurrencyUSD(value),
    "campaign_spend_budget.remaining_budget": (value: number) => formatBudget(value),
  },
};
