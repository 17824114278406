import { ResultSet } from "@cubejs-client/core";
import utils from ".";
import { CubeWidgetDataSource } from "data/utils";

export const fromResultSet = (dataSource: CubeWidgetDataSource, resultSet: ResultSet) => {
  const field = resultSet.normalizePivotConfig().x;
  const formatter =
    field && field[0]
      ? utils.formatting.getDataSourceFieldFormatter(dataSource, field[0], "axis")
      : undefined;

  return resultSet.categories(dataSource.pivotConfig).map((c) => {
    return formatter ? formatter(c.x) : c.x;
  });
};
