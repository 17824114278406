import * as React from 'react';

import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';

import api from 'api';
import { useEffect, useState } from 'react';
import { CreateGroupRequest, CreateGroupRequestSchema, GroupObject } from 'api/endpoints/groups';
import { FileUploadResponseObject } from 'api/endpoints/files';

import { Form } from 'components/form/form';
import InputField, { InputFieldType } from 'components/form/input';
import { FormFieldVariant } from 'components/form/common';
import { SubmitButton } from 'components/form/submit-button';

import FileUploadIndicator from 'components/cards/file-upload-indicator';
import FileDropzoneField from 'components/form/file-dropzone-field';
import FilePreview from 'components/form/file-preview';
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';

import Skeleton from 'react-loading-skeleton';
import { sizedGroupLogoImageURL } from 'utils/imgix-helpers';
import { group } from 'console';
import * as quartzite from 'quartzite';
import value from '*.ico';

function newGroupObj(): CreateGroupRequest {
  return _.clone({
    id: '0',
    name: '',
    logo: '*'
  });
}

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IGroupGeneralTabProps {
  groupId: string,
  isNew: boolean
}

export default function GroupGeneralTab(props: IGroupGeneralTabProps) {
  let [initialValue, setInitialValue] = useState<CreateGroupRequest>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [shouldOpenBrowseDialog, setShouldOpenBrowseDialog] = useState(false);
  let [uploadedFilePath, setUploadedFilePath] = useState('');
  let [uploadingFileName, setUploadingFileName] = useState('');
  let [editedGroup, setEditedGroup] = useState<GroupObject>({} as GroupObject);
  let navigate = useNavigate();

  const onUploadNewPicture = () => {
    let group = editedGroup;
    group.logo_url = '*';
    setEditedGroup(group);
    setShouldOpenBrowseDialog(true);
  }

  const onRemovePicture = () => {
    setUploadedFilePath('*');
    let group = editedGroup;
    group.logo_url = '*';
    setEditedGroup(group);
  }

  const onDeleteGroup = (groupId: string) => {
    api.groups.deleteGroup({ id: groupId }).fetch().then((response) => {
      navigate('/groups');
    }).catch((error) => {
      // TODO: handle error
      console.log(error);
    });
  }

  const onSubmit = (values: any, formikHelpers: any) => {
    let update: Promise<GroupObject>;
    const payload: CreateGroupRequest = {
      id: props.groupId,
      name: values.name,
      logo: uploadedFilePath
    };

    if (!props.isNew) {
      update = api.groups.updateGroup(payload).fetch();
    } else {
      update = api.groups.newGroup(payload).fetch();
    }
    return update
      .then((response) => {
        if (props.isNew) {
          navigate('/groups');
        }
      })
  }

  const loadGroup = async () => {
    let group = await api.groups.getGroup({ id: props.groupId }).fetch();
    if (group) {
      setEditedGroup(group);
      setInitialValue({ logo: '', ...group });
    }
  }


  const initForm = () => {
    if (!props.isNew) {
      loadGroup();
    } else {
      setInitialValue(newGroupObj());
    }
  }

  useEffect(initForm, []);

  return (
    <>
      {initialValue ? (

        <Form
          validationSchema={CreateGroupRequestSchema.omit({ logo: true, logo_url: true }).nonstrict()}
          initialValues={initialValue}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => (

            <div className="o-row">
              <div className="o-col-8@md">
                <div className="u-mb-spacer-base-large">
                  <h6>About</h6>
                  <InputField
                    type={InputFieldType.text}
                    name={`name`}
                    placeholder="Name"
                    label="Name"
                    variant={FormFieldVariant.fill}
                  />
                </div>
                <div className="u-mb-spacer-base-large">
                  <h6>Logo</h6>
                  {isUploading ? (
                    <FileUploadIndicator
                      fileName={uploadingFileName}
                    />
                  ) : (
                    (editedGroup.logo_url && editedGroup.logo_url !== '*') ? (
                      <FilePreview
                        filePreviewUrl={sizedGroupLogoImageURL(editedGroup.logo_url)}
                        newButtonLabel={"Upload new logo"}
                        removeButtonLabel={"Remove logo"}
                        descriptionLabel={undefined}
                        newHandler={onUploadNewPicture}
                        removeHandler={onRemovePicture}
                        compactLayout={false}
                      />
                    ) : (
                      <FileDropzoneField
                        setFieldValue={setFieldValue}
                        dropHandler={(acceptedFiles: any[]) => {
                          if (acceptedFiles.length > 0) {
                            let formData = new FormData();
                            let file = acceptedFiles[0];
                            setUploadingFileName(file['name']);
                            formData.append('file', file);

                            setIsUploading(true);
                            api.files.uploadFile(formData, 'images').fetch().then((response) => {
                              let uploaded_file = response as FileUploadResponseObject;
                              setUploadedFilePath(uploaded_file.file_path);

                              let group = editedGroup;
                              group.logo_url = uploaded_file.file_url;
                              setEditedGroup(group);
                            }).catch((error) => {
                              console.error(error);
                            }).finally(() => {
                              setIsUploading(false);
                            });
                          }
                        }
                        }
                        name={'logo'}
                        shouldOpenBrowseDialog={shouldOpenBrowseDialog}
                        description="Use a JPEG/PNG image no larger than 300x300 pixels."
                      />
                    )
                  )}
                </div>
              </div>
              <div className="o-col-4@md">
                <div className="c-card c-card--bg-blue">
                  <div className="c-card__body">
                    {props.isNew ?
                      (
                        <div className="c-card__header">
                          <h6>Create</h6>
                          <div className="c-card__desc">
                            <p>You are adding a new client, you will be able to configure their dashboards and users afterwards.</p>
                          </div>
                        </div>
                      ) : (
                        <div className="c-card__header">
                          <h6>Update</h6>
                          <div className="c-card__desc">
                            {editedGroup.updated_at ? (
                              <p>This client was last updated {formatDate(editedGroup.updated_at)}.</p>
                            ) : (
                              <p>This is the first update for this client.</p>
                            )}
                          </div>
                        </div>
                      )
                    }
                    <div className="o-row o-row--fluid c-button-group">
                      <div className="o-col">
                        <SubmitButton disabled={isUploading}><span>{props.isNew ? 'Create' : 'Update'} client</span></SubmitButton>
                      </div>
                      <div className="o-col c-button-group__inline">
                        {props.isNew ?
                          (
                            <div onClick={(e) => { e.preventDefault(); navigate(-1) }} className="c-link-cta-basic"><span>Cancel</span></div>
                          ) : (
                            <div onClick={(e) => { e.preventDefault(); setIsDeleting(true); }} className="c-link-cta-basic"><IconTrash className="o-svg-icon" /><span>Delete client</span></div>
                          )
                        }
                      </div>
                    </div>
                    <DeleteConfirmationAlert
                      resource_label={editedGroup.name}
                      onDelete={() => {
                        onDeleteGroup(props.groupId);
                        setIsDeleting(false);
                      }}
                      onCancel={() => {
                        setIsDeleting(false);
                      }}
                      show={isDeleting}
                      type={DeleteConfirmationAlertType.Card}
                    />
                  </div>
                </div>
              </div>
            </div>

          )}
        </Form>
      ) : (
        <GroupGeneralTabSkeleton />
      )
      }
    </>
  );
}

export function GroupGeneralTabSkeleton() {
  return (
    <div className="o-row">
      <div className="o-col-8@md">
        <div className="u-mb-spacer-base-large">
          <h6><Skeleton width={250} /></h6>
          <Skeleton height={50} />
        </div>
        <div className="u-mb-spacer-base-large">
          <h6><Skeleton width={250} /></h6>
          <Skeleton height={50} width={400} />
        </div>
      </div>
      <div className="o-col-4@md">
        <div className="c-card c-card--bg-blue">
          <div className="c-card__body">
            <div className="c-card__header">
              <h6><Skeleton width={250} /></h6>
              <div className="c-card__desc">
                <p><Skeleton count={3} /></p>
              </div>
            </div>
            <div className="o-row o-row--fluid c-button-group">
              <div className="o-col">
                <Skeleton height={50} />
              </div>
              <div className="o-col c-button-group__inline">
                <Skeleton height={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
