import React, { createContext, useCallback, useContext, useState } from "react";
import utils from "components/cube/utils";
import _ from "lodash";

export type ContextFilterDateRange = [Date | null, Date | null];

export type CubeContextFilterValue = string[] | ContextFilterDateRange | null | undefined;

export type CubeContextFilter = { [key: string]: CubeContextFilterValue };

interface CubeDashboardContextProps {
  filter: CubeContextFilter;
  setDefaultFilter: (defaultFilter: CubeContextFilter) => void;
  updateFilter: (key: string, value: CubeContextFilterValue) => void;
  isReady: () => boolean;
  isDirty: () => boolean;
  clear: () => void;
  getColor: (label: string) => string;
}

export const CubeDashboardContext = createContext<CubeDashboardContextProps | undefined>(undefined);

interface ICubeDashboardContextProviderProps {}

type DashboardColors = Record<string, string>;

export const CubeDashboardContextProvider: React.FC<ICubeDashboardContextProviderProps> = ({
  children,
}) => {
  const [defaults, setDefaults] = useState<CubeContextFilter | undefined>(undefined);
  const [filter, setFilters] = useState<CubeContextFilter>({});
  const [colors, setColors] = React.useState<DashboardColors>({});

  const getColor = useCallback((label: string) => {
    var updatedColors = colors;
    if (!updatedColors.hasOwnProperty(label)) {
      updatedColors[label] = utils.colors.get(Object.keys(updatedColors).length);
      setColors((prev) => ({ ...prev, ...updatedColors }));
    }
    return updatedColors[label];
  }, [colors]);

  const updateFilter = (key: string, value: CubeContextFilterValue) => {
    if (value === undefined || value === null || (_.isArray(value) && value.length === 0)) {
      // remove from the filter object
      filter[key] && delete filter[key];
      setFilters({ ...filter });
      return;
    }

    setFilters({ ...filter, ...{ [key]: value } });
  };

  const setDefaultFilter = (defaultFilter: CubeContextFilter) => {
    if (!isReady()) {
      setFilters(defaultFilter);
      setDefaults(defaultFilter);
    }
  }

  const isReady = () => {
    return defaults !== undefined;
  }

  const isDirty = () => {
    return !_.isEmpty(_.omit(filter, Object.keys(defaults ?? {})));
  };

  const clear = () => {
    setFilters({ ..._.pick(filter, Object.keys(defaults ?? {})) });
  };

  const value: CubeDashboardContextProps = {
    filter,
    setDefaultFilter,
    updateFilter,
    isReady,
    isDirty,
    clear,
    getColor
  };

  return <CubeDashboardContext.Provider value={value}>{children}</CubeDashboardContext.Provider>;
};

export const useCubeDashboardContext = () => {
  const context = useContext(CubeDashboardContext);
  if (!context) {
    throw new Error("useCubeDashboardContext must be used within a CubeDashboardContextProvider");
  }
  return context;
};
