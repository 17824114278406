import * as React from "react";
import { Chart as ChartJS, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ICubeWidgetComponentProps } from "./types";
import utils from "components/cube/utils";
import { useColors } from "components/cube/hooks/useColors";

ChartJS.register(BarElement, Title, Tooltip, Legend);

interface Dataset {
  key: string;
  label: string;
  data: any[];
  backgroundColor: string;
}

export interface IBarChartWidget extends ICubeWidgetComponentProps {
  sharedColors?: boolean;
}

const BarChartWidget: React.FunctionComponent<IBarChartWidget> = ({
  dataSource,
  resultSet,
  sharedColors,
}) => {
  const labels = utils.labels.fromResultSet(dataSource, resultSet);
  const colors = useColors(resultSet.seriesNames(dataSource.pivotConfig).map((item) => item.shortTitle), sharedColors);

  const datasets: Dataset[] = resultSet.series(dataSource.pivotConfig).map((item, i) => {
    return {
      key: item.key,
      label: (dataSource.labelsConfig ?? {})[item.key]
        ? dataSource.labelsConfig![item.key]
        : item.shortTitle,
      data: item.series.map((item) => item.value),
      backgroundColor: colors[i],
    };
  });

  const xFormatter = resultSet
    .seriesNames(dataSource.pivotConfig)
    .map((f) => utils.formatting.getDataSourceFieldFormatter(dataSource, f.key, "axis"))
    .find((f) => f !== null);

  return (
    <Bar
      data={{ labels, datasets }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        scales: {
          x: {
            border: {
              dash: [1, 3],
            },
            grid: {
              drawTicks: false,
            },
            ticks: {
              callback: function (value: any, _: any, __: any) {
                return xFormatter ? xFormatter(value) : value;
              },
            },
          },
          y: {
            border: {
              dash: [1, 3],
            },
            grid: {
              drawTicks: false,
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            xAlign: "left",
            callbacks: {
              label: (tooltipItem) => {
                const dataset = tooltipItem.dataset as Dataset;
                const formatter = utils.formatting.getDataSourceFieldFormatter(
                  dataSource,
                  dataset.key,
                  "tooltip"
                );
                const label = dataset.label;
                const value = formatter ? formatter(tooltipItem.raw) : tooltipItem.formattedValue;
                return `${label}: ${value}`;
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              padding: 30,
            },
          },
        },
      }}
    />
  );
};

export default BarChartWidget;
