const colors = [
  "#78bfd1",
  "#d7c72f",
  "#409c9b",
  "#a3bf2e",
  "#e76f69",
  "#5c3b6e",
  "#c35e0c",
  "#8a8a8a",
  "#f4ca7d",
  "#35654d",
  "#0A3A6E",
  "#F4C6DB",
];

export const get = (i: number) => colors[i % 12];
