import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ICubeWidgetComponentProps } from "./types";
import ChartDataLabels from "chartjs-plugin-datalabels";
import utils from "./utils";
import { useColors } from "components/cube/hooks/useColors";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Dataset {
  key: string;
  label: string;
  data: any[];
  borderColor: string;
  backgroundColor: string;
}

export interface ILineChartWidget extends ICubeWidgetComponentProps {
  sharedColors?: boolean;
}

const LineChartWidget: React.FunctionComponent<ILineChartWidget> = ({
  dataSource,
  resultSet,
  sharedColors,
}) => {
  const labels = utils.labels.fromResultSet(dataSource, resultSet);
  const colors = useColors(resultSet.seriesNames(dataSource.pivotConfig).map((item) => item.shortTitle), sharedColors);

  const datasets: Dataset[] = resultSet.series(dataSource.pivotConfig).map((item, i) => {
    return {
      key: item.key,
      label: (dataSource.labelsConfig ?? {})[item.key]
        ? dataSource.labelsConfig![item.key]
        : item.shortTitle,
      data: item.series.map((item) => item.value),
      borderColor: colors[i],
      backgroundColor: colors[i],
    };
  });

  return (
    <Line
      data={{ labels, datasets }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
          line: {
            borderWidth: 4,
            pointStyle: "circle",
            fill: false,
            pointRadius: 0,
            pointBackgroundColor: "white",
            pointHitRadius: 10,
            pointBorderWidth: 4,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 4,
            cubicInterpolationMode: "monotone",
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value: any, _: any, __: any) {
                return value;
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              labelColor: (context) => {
                return {
                  borderColor: "transparent",
                  backgroundColor: (context.dataset as Dataset).borderColor,
                };
              },
              label: (tooltipItem) => {
                const dataset = tooltipItem.dataset as Dataset;
                const formatter = utils.formatting.getDataSourceFieldFormatter(
                  dataSource,
                  dataset.key,
                  "tooltip"
                );
                const label = tooltipItem.dataset.label;
                const value = formatter ? formatter(tooltipItem.raw) : tooltipItem.formattedValue;
                return `${label}: ${value}`;
              },
            },
          },
          datalabels: {
            formatter: (value, context) => {
              const formatter = utils.formatting.getDataSourceFieldFormatter(
                dataSource,
                datasets[context.datasetIndex].key,
                "datalabels"
              );
              return formatter ? formatter(value) : value;
            },
            display: "auto",
            align: "top",
            offset: 4,
          },
          legend: {
            position: "bottom",
            labels: {
              padding: 30,
            },
          },
        },
      }}
      plugins={[ChartDataLabels]}
    />
  );
};

export default LineChartWidget;
