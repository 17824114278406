import { createContext, useContext } from 'react';
import { Instance } from 'mobx-state-tree';
import { RootStore } from 'store/root';

export const store = RootStore.create({
  tokens: {
    accessToken: null,
    refreshToken: null
  },
  errors: {
    app: []
  }
});

export type RootStoreInstance = Instance<typeof RootStore>;
export const RootStoreContext = createContext<null | RootStoreInstance>(null);
RootStoreContext.displayName = 'RootStoreContext';

export const Provider = RootStoreContext.Provider;

export function useStore() {
  const store = useContext<null | RootStoreInstance>(RootStoreContext);

  if (store === null) {
    throw new Error("Couldn't initialize an instance of application data store.");
  }

  return store;
}
