import * as React from 'react';

import classNames from 'classnames';

// Hooks
import { useContext, useRef, useState } from 'react';

// Icons
import { ReactComponent as IconDropdown } from 'assets/svg/dropdown.svg';
import { ReactComponent as IconPen } from 'assets/svg/pen.svg';
import { ReactComponent as IconMore } from 'assets/svg/more.svg';
import { ReactComponent as IconEye } from 'assets/svg/eye.svg';
import { ReactComponent as IconTrash } from 'assets/svg/trash.svg';

import { DashboardObject } from 'api/endpoints/dashboards';
import { Link } from 'react-router-dom';
import { Button, ButtonScope, ButtonSizing } from 'components/button';
import useClickAway from 'hooks/use-click-away';
import { listingContext } from './listing-context';


interface IDashboardCardProps {
  dashboard: DashboardObject,
  deleteHandler: (ids: string[]) => void,
  selectHandler: (ids: string[]) => void,
}

const DashboardCard: React.FunctionComponent<IDashboardCardProps> = ({ dashboard, deleteHandler, selectHandler }) => {

  const classNameSelected = 'c-card__link-hidden';

  const [showActions, setShowActions] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const { selection } = useContext(listingContext);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setShowActions(false));

  function editDashboardLink(id: string): string {
    return `/dashboards/${id}/edit`;
  }

  function isSelected(id: string) {
    if (selection && selection.indexOf(id) > -1) {
      return true;
    }
    return false;
  }

  if (!dashboard.id) return null;

  return (
    <div className="c-listing__item">
      <div ref={trigger} className={classNames(['c-card', 'c-card--link', 'c-card--bg-white', { 'is-selected': isSelected(dashboard.id) }, { 'has-popup-open': showActions }])}>
        <div className="c-card__body">
          <div className="c-card__content">
            <p className="c-card__title"><Link to={editDashboardLink(dashboard.id)}>{dashboard.name}</Link></p>
            <div className="c-card__info">
            </div>
          </div>
          <div className="c-card__footer">
            <div className="c-link-cta-light c-card__link-main"><span>View</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></div>
            <Link to={editDashboardLink(dashboard.id)} className="c-link-cta-light c-card__link-hidden"><IconPen className="o-svg-icon" /><span>Edit</span></Link>
            <div className="c-card__actions c-card__link-hidden">
              <div onClick={(event) => setShowActions(!showActions)} className="c-card__actions-toggle">
                <IconMore className="o-svg-icon" />
              </div>
              <div className="c-popup">
                <div className="c-popup__header c-popup__header--sm">
                  <p className="c-popup__title">Actions</p>
                </div>
                <div className="c-popup__body">
                  <ul className="c-popup__list c-popup__list--small">
                    <li>
                      <div onClick={(event) => { event.preventDefault() }}>
                        <IconEye className="o-svg-icon" /><span>Log in as Client</span>
                      </div>
                    </li>
                    <li >
                      <Link to={editDashboardLink(dashboard.id)}>
                        <IconPen className="o-svg-icon" /><span>Edit Client</span>
                      </Link>
                    </li>
                    <li className="c-popup__divider c-popup__divider--top">
                      <div onClick={(event) => { event.preventDefault(); setDeleting(true); setShowActions(false) }}>
                        <IconTrash className="o-svg-icon" /><span>Delete Client<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`c-form-element c-form-element--style-fill c-form-element--checkbox c-card__checkbox ${classNameSelected}`}>
              <div className="c-form-element__field">
                <input checked={isSelected(dashboard.id)} type="checkbox" id={`check-listing-item-${dashboard.id}`} onChange={(event) => { if (dashboard.id) selectHandler([dashboard.id]); }} />
                <label htmlFor={`check-listing-item-${dashboard.id}`}></label>
              </div>
            </div>
          </div>
          <div className={classNames(['c-card__alert', 'c-card__alert--error', { 'u-hidden': !isDeleting }])}>
            <p className="c-card__alert-question">Are you sure you want to delete <strong>{dashboard.name}</strong>?</p>
            <div className="c-card__alert-options">
              <Button onClick={(event) => { if (dashboard.id) deleteHandler([dashboard.id]); setDeleting(false); }} scope={ButtonScope.white} outline sizing={ButtonSizing.small}><span>Yes</span></Button>
              <Button onClick={(event) => setDeleting(false)} scope={ButtonScope.white} outline sizing={ButtonSizing.small}><span>No</span></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
