import * as React from 'react';

interface IFooterProps {
  ref: React.Ref<HTMLElement>;
}

const Footer: React.ForwardRefExoticComponent<IFooterProps> = React.forwardRef((props, ref) => {
  return (
    <footer id="site-footer" className="c-site-footer c-color--invert">
      <div className="o-container-fluid o-container--full">
        <div className="c-site-footer__wrapper">
          <div className="c-site-logo c-site-logo--invert c-site-logo--basic"></div>

          <div className="c-site-footer__info">
            <p>©{new Date().getFullYear()} Reportiv Global Inc. All Rights Reserved.</p>
            <ul>
              {/* <li><a href="https://www.convertiv.com/privacy-policy/">Privacy Policy</a></li> */}
              <li><a href="https://www.convertiv.com/privacy-policy/">Terms & Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
