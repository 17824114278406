import HttpClient from 'api/http-client';
import * as z from 'zod';

export const SendResetLinkRequestSchema = z.object({
  email: z.string().email().nonempty(),
  reset_url: z.string().url().nonempty(),
});

export const SendResetLinkResponseSchema = z.object({
  message: z.string().nonempty()
});

export const ResetPasswordRequestSchema = z.object({
  email: z.string().email().nonempty(),
  password: z.string().nonempty(),
  password_confirmation: z.string().nonempty(),
  token: z.string().nonempty()
});

export type SendResetLinkRequest = z.TypeOf<typeof SendResetLinkRequestSchema>
export type SendResetLinkResponse = z.TypeOf<typeof SendResetLinkResponseSchema>
export type ResetPasswordRequest = z.TypeOf<typeof ResetPasswordRequestSchema>

export function sendResetLink(data: SendResetLinkRequest) {
  return new HttpClient().request<SendResetLinkRequest>({
    method: 'post',
    url: '/password/forgot',
    validator: SendResetLinkResponseSchema,
    data,
  });
}

export function resetPassword(data: ResetPasswordRequest) {
  return new HttpClient().request<ResetPasswordRequest>({
    method: 'post',
    url: '/password/reset',
    data,
  });
}
