import { PivotConfig } from "@cubejs-client/core";
import { formatCurrencyUSD, formatQuarter } from "data/formatters";
import { FormatConfig, LabelsConfig, MappableFilterList, TCubeQuery } from "data/types";

export const title: string = "Budget vs. Cost";

export const query: TCubeQuery = [
  {
    measures: ["campaign_spend_budget.spend", "campaign_spend_budget.budget"],
    timeDimensions: [
      {
        dimension: "campaign_spend_budget.date",
        granularity: "quarter",
      },
    ],
    order: {
      "campaign_spend_budget.date": "asc",
    },
  },
];

export const pivotConfig: PivotConfig = {
  x: ["campaign_spend_budget.date"],
  y: ["measures"],
};

export const formatConfig: FormatConfig = {
  any: {
    "campaign_spend_budget.budget": (value) => formatCurrencyUSD(value),
    "campaign_spend_budget.spend": (value) => formatCurrencyUSD(value),
  },
  axis: {
    "campaign_spend_budget.date": (value) => formatQuarter(value),
  },
};

export const labelsConfig: LabelsConfig = {
  "campaign_spend_budget.spend": "Cost",
}

export const filteredBy: MappableFilterList = [["campaign_report.platform", "campaign_spend_budget.platform"]];
