import { useEffect } from 'react';


export interface IPageLoaderProps {
}

export default function PageLoader(props: IPageLoaderProps) {
  useEffect(() => {
    window.loader(true);

    return () => {
      window.loader(false);
    }
  });
  return null;
}
