import React from 'react';
import Row from "components/layout/row";
import Column from "components/layout/column";
import Filter from "components/cube/elements/filter";
import DimensionFilter from "components/cube/elements/filters/dimension";
import TimeFilter from "components/cube/elements/filters/time";
import { startOfMonth } from "date-fns";

import { CubeDataSourceRegistry } from "data";
import Widget, { WidgetType } from '../elements/widget';

export default function AdReportingDefault() {
  return (
    <div>
      <Filter>
        <TimeFilter dimension="campaign_report.date" defaultValue={[startOfMonth(new Date()), new Date()]} label="Date" />
        <DimensionFilter dimension="campaign_report.platform" label="Platform" />
      </Filter>
      <div>
        <Row>
          <Column width={3}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_COST")}
            />
          </Column>
          <Column width={3}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_CONVERSIONS")}
            />
          </Column>
          <Column width={3}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_CPA")}
            />
          </Column>
          <Column width={3}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_CVR")}
            />
          </Column>
        </Row>
        <Row>
          <Column width={6}>
            <Widget
              type={WidgetType.BubbleChart}
              height="35vh"
              dataSource={CubeDataSourceRegistry.get("CONVERSIONS_VS_CPA")}
              sharedColors={true}
              />
          </Column>
          <Column width={6}>
            <Widget
              type={WidgetType.BarChart}
              height="35vh"
              dataSource={CubeDataSourceRegistry.get("BUDGET_VS_COST")}
              />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              title="Performance by Platform"
              type={WidgetType.PieChart}
              height="45vh"
              dataSource={[
                CubeDataSourceRegistry.get("PLATFORM_IMPRESSIONS"),
                CubeDataSourceRegistry.get("PLATFORM_CLICKS"),
                CubeDataSourceRegistry.get("PLATFORM_CONVERSIONS"),
                CubeDataSourceRegistry.get("PLATFORM_COST"),
              ]}
              sharedColors={true}
            />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              type={WidgetType.LineChart}
              height="60vh"
              dataSource={CubeDataSourceRegistry.get("METRIC_TREND")}
            />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              type={WidgetType.Table}
              dataSource={CubeDataSourceRegistry.get("PLATFORM_PERFORMANCE_BREAKDOWN")}
            />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              type={WidgetType.Table}
              dataSource={CubeDataSourceRegistry.get("CAMPAIGN_PERFORMANCE_BREAKDOWN")}
            />
          </Column>
        </Row>
      </div>
    </div>
  );
}
