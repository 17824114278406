import React from 'react';
import Row from "components/layout/row";
import Column from "components/layout/column";
import Filter from "components/cube/elements/filter";
import DimensionFilter from "components/cube/elements/filters/dimension";
import TimeFilter from "components/cube/elements/filters/time";
import { startOfMonth } from "date-fns";

import { CubeDataSourceRegistry } from "data";
import Widget, { WidgetType } from 'components/cube/elements/widget';
import { LabelsConfig } from 'data/types';

export const labels: LabelsConfig = {
  "campaign_report.spend": "Cost",
  "campaign_spend_budget.spend": "Cost",
  "campaign_report.conversions": "Purchases",
  "campaign_report.conversions_value": "Revenue",
};

const dateFilter = ["campaign_report.date"];
const dimensionsFilter = ["campaign_report.platform"];
// const budgetFilter: MappableFilterList = [["campaign_report.date", "campaign_spend_budget.date"], ["campaign_report.platform", "campaign_spend_budget.platform"]];


export default function AdReportingCatania() {
  return (
    <div>
      <Filter>
        <TimeFilter dimension="campaign_report.date" defaultValue={[startOfMonth(new Date()), new Date()]} label="Date" />
        <DimensionFilter dimension="campaign_report.platform" label="Platform" />
      </Filter>
      <div>
        <Row>
          <Column width={2}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_COST")}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
          <Column width={2}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_CONVERSIONS")}
              title= "Total Purchases"
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
          <Column width={2}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_REVENUE")}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
          <Column width={2}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_ROAS")}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
          <Column width={2}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_CPA")}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
          <Column width={2}>
            <Widget
              type={WidgetType.Indicator}
              dataSource={CubeDataSourceRegistry.get("TOTAL_CVR")}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
        </Row>
        <Row>
          <Column width={6}>
            <Widget
              type={WidgetType.BubbleChart}
              height="35vh"
              dataSource={CubeDataSourceRegistry.get("CONVERSIONS_VS_CPA")}
              title="Purchases vs. CPA"
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
              sharedColors={true}
              />
          </Column>
          <Column width={6}>
            <Widget
              type={WidgetType.BarChart}
              height="35vh"
              dataSource={CubeDataSourceRegistry.get("BUDGET_VS_COST")}
              labelsConfig={labels}
              />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              title="Performance by Platform"
              type={WidgetType.PieChart}
              height="45vh"
              dataSource={[
                CubeDataSourceRegistry.get("PLATFORM_IMPRESSIONS"),
                CubeDataSourceRegistry.get("PLATFORM_CLICKS"),
                CubeDataSourceRegistry.get("PLATFORM_CONVERSIONS"),
                CubeDataSourceRegistry.get("PLATFORM_COST"),
              ]}
              tabLabels={["Impressions", "Clicks", "Purchases", "Cost"]}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
              sharedColors={true}
            />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              type={WidgetType.LineChart}
              height="60vh"
              dataSource={CubeDataSourceRegistry.get("METRIC_TREND_ZILDJIAN")}
              labelsConfig={labels}
              filteredBy={[...dimensionsFilter]}
            />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              type={WidgetType.Table}
              dataSource={CubeDataSourceRegistry.get("PLATFORM_PERFORMANCE_BREAKDOWN_ZILDJIAN")}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
        </Row>
        <Row>
          <Column width={12}>
            <Widget
              type={WidgetType.Table}
              dataSource={CubeDataSourceRegistry.get("CAMPAIGN_PERFORMANCE_BREAKDOWN_ZILDJIAN")}
              labelsConfig={labels}
              filteredBy={[...dateFilter, ...dimensionsFilter]}
            />
          </Column>
        </Row>
      </div>
    </div>
  );
}
