import * as React from 'react';

import { Routes, Route, useParams } from 'react-router-dom';
import { UserDetails } from 'components/cards/user-edit';
import { usersRefresh } from 'utils/users-refresh-singleton';

export interface IUserEditModalProps {
}

const UserEditModal: React.FunctionComponent<IUserEditModalProps> = () => {
  let { groupId } = useParams() as {
    groupId: string | undefined,
    userId: string | undefined,
  };

  function onUpdate() {
    usersRefresh.needsRefresh = true;
  }

  return (
    <Routes>
      <Route path={`/group/:groupId/users/:userId/edit`} element={
        <UserDetails
          isNew={false}
          nextLocation={`/group/${groupId}/users`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      } />
      <Route path={`/group/:groupId/users/new`} element={
        <UserDetails
          isNew={true}
          nextLocation={`/group/${groupId}/users`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      } />
      <Route path={`/users/:userId/edit`} element={
        <UserDetails
          isNew={false}
          nextLocation={`/users`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      } />
      <Route path={`/users/new`} element={
        <UserDetails
          isNew={true}
          nextLocation={`/users`}
          onUpdate={onUpdate}
          isProfile={false}
        />
      } />
    </Routes>
  );
};

export default UserEditModal;
