import * as z from 'zod';

class ValidationError extends Error {
  public name: string = 'ValidationError';
  inner: { path: string; message: string} [] = [];
}

function createValidationError(e: z.ZodError) {
  const error = new ValidationError(e.message);

  error.inner = e.errors.map((error) => ({
    message: error.message,
    path: error.path.join('.'),
  }));

  return error;
}


// Wrap your zod schema before passing it to Formik's `validationSchema` prop
export function toFormikSchema(schema: z.ZodSchema<any>) {
  return {
    async validate(obj: z.ZodTypeAny) {
      try {
        schema.parse(obj);
      } catch (err) {

        if (err instanceof z.ZodError) {
          throw createValidationError(err);
        } else {
          throw err;
        }
      }
    },
  }
}
