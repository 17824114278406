import HttpClient from 'api/http-client';
import * as z from 'zod';
import { GetListingRequestSchema, LinksSchema, MetaSchema } from 'api/endpoints/common';

const dateRegex = /^(?<fullyear>\d{4})-(?<month>0[1-9]|1[0-2])-(?<mday>0[1-9]|[12][0-9]|3[01])T(?<hour>[01][0-9]|2[0-3]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9]|60)(?<secfrac>\.[0-9]+)?(Z|(\+|-)(?<offset_hour>[01][0-9]|2[0-3]):(?<offset_minute>[0-5][0-9]))$/i;

export const GroupObjectSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  created_at: z.string().regex(dateRegex),
  updated_at: z.string().regex(dateRegex),
  users_count: z.number().int(),
  dashboards_count: z.number().int(),
  logo_url: z.string(),
  first_dashboard_id: z.string().optional()
});

export const CreateGroupRequestSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  logo: z.string(),
  logo_url: z.string().optional()
});
export const CreateGroupResponseSchema = GroupObjectSchema;

export const GetGroupsResponseSchema = z.object({
  data: z.array(GroupObjectSchema),
  links: LinksSchema,
  meta: MetaSchema,
});

export const GetGroupsRequestSchema = GetListingRequestSchema;

export const GroupObjectIdSchema = z.object({
  id: z.string(),
});


export type GroupObject = z.TypeOf<typeof GroupObjectSchema>
export type CreateGroupRequest = z.TypeOf<typeof CreateGroupRequestSchema>
export type GetGroupsRequest = z.TypeOf<typeof GetGroupsRequestSchema>
export type GetGroupsResponse = z.TypeOf<typeof GetGroupsResponseSchema>
export type GroupObjectId = z.TypeOf<typeof GroupObjectIdSchema>

export function getGroups(data: GetGroupsRequest) {
  return new HttpClient().request<GetGroupsResponse>({
    method: 'get',
    url: '/groups',
    validator: GetGroupsResponseSchema,
    params: data,
  });
}

export function newGroup(data: CreateGroupRequest) {
  return new HttpClient().request<GroupObject>({
    method: 'post',
    url: '/groups',
    validator: CreateGroupResponseSchema,
    data,
  });
}

export function getGroup(data: GroupObjectId) {
  return new HttpClient().request<GroupObject>({
    method: 'get',
    url: `/groups/${data.id}`,
    validator: GroupObjectSchema,
  });
}

export function deleteGroup(data: GroupObjectId) {
  return new HttpClient().request({
    method: 'delete',
    url: `/groups/${data.id}`,
    data,
  });
}

export function updateGroup(data: CreateGroupRequest) {
  return new HttpClient().request<GroupObject>({
    method: 'put',
    url: `/groups/${data.id}`,
    data,
  });
}
