import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import MainLayout from "layouts/main";
import BasicLayout from "layouts/basic";

import NotFoundPage from "pages/not-found";
import AccessDeniedPage from "pages/access-denied";
import LoginPage from "pages/login";
import GroupsPage from "pages/groups";
import GroupPage from "pages/group";
import UsersPage from "pages/users";
import DashboardsPage from "pages/dashboards";
import DashboardPage from "pages/dashboard";
import CubeDashboardPage from "pages/cube-dashboard";
import { useStore } from "store";
import AddGroupPage from "pages/group-add";
import ForgotPasswordPage from "pages/password-forgot";
import ResetPasswordPage from "pages/password-reset";
import ProfilePage from "pages/profile";

// import CutupLayout from 'layouts/cutup';
// import UsersPageCutup from 'pages/cutup/users';
// import EditUserPage from 'pages/cutup/edit-user';
// import GroupsPageCutup from 'pages/cutup/groups';
// import EditGroupPage from 'pages/cutup/edit-group';
// import LoginPageCutup from 'pages/cutup/login';
// import WebkitPage from 'pages/cutup/webkit';

const loginPath = "/login";

export interface LocationState {
  from?: {
    pathname: string;
  };
}

export interface IAuthenticatedRouteProps {
  children: React.JSX.Element;
}

function AuthenticatedRoute({ children }: IAuthenticatedRouteProps) {
  const store = useStore();
  const currentLocation = useLocation();

  if (store.isLoggedIn) {
    return children;
  } else {
    return <Navigate to={loginPath} state={{ from: currentLocation }} />;
  }
}

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path={loginPath}
        element={
          <BasicLayout>
            <LoginPage />
          </BasicLayout>
        }
      />
      <Route
        path="/password/forgot"
        element={
          <BasicLayout>
            <ForgotPasswordPage />
          </BasicLayout>
        }
      />
      <Route
        path="/password/reset"
        element={
          <BasicLayout>
            <ResetPasswordPage />
          </BasicLayout>
        }
      />

      <Route
        path="/groups"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <GroupsPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/group/:groupId/*"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <GroupPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/groups/new"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <AddGroupPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <UsersPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/cube-dashboard"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <CubeDashboardPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/dashboards"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <DashboardsPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/dashboard/:id/*"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <DashboardPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/401"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <AccessDeniedPage />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthenticatedRoute>
            <MainLayout>
              <ProfilePage returnTo="" />
            </MainLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/"
        element={
          <AuthenticatedRoute>
            <BasicLayout>
              <LoginPage />
            </BasicLayout>
          </AuthenticatedRoute>
        }
      />
      <Route
        path="*"
        element={
          <MainLayout>
            <NotFoundPage />
          </MainLayout>
        }
      />
    </Routes>
  );
}
