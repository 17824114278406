import * as React from 'react';
import Notification from 'components/notification';
import { useStore } from 'store';

export interface IAppErrorsProps {
}

export function AppErrors (props: IAppErrorsProps) {
  const store = useStore();

  function getNiceErrorName(type: string) {
    let title: string = '';
    switch (type) {
      case 'network':
        title = 'Connectivity problem.'
        break;
    }

    return title;
  }

  if (store.errors.hasError) {
    return (
      <>
      {store.errors.app.map((error) =>
        <Notification type="error">
          <p><strong>{getNiceErrorName(error.type)}</strong></p>
          <p>{error.message}</p>
        </Notification>
      )}
      </>
    );
  } else {
    return null;
  }
}
