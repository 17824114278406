import { formatCurrencyUSD } from "data/formatters";
import { FormatConfig, TCubeQuery } from "data/types";

export const title: string = "Total Revenue";

export const query: TCubeQuery = {
  measures: ["campaign_report.conversions_value"],
  timeDimensions: [
    {
      dimension: "campaign_report.date",
      granularity: "month",
    },
  ]
};

export const filteredBy = ["campaign_report.date", "campaign_report.platform"];

export const formatConfig: FormatConfig = {
  any: {
    "campaign_report.conversions_value": (value) => formatCurrencyUSD(value),
  },
};
