import { PivotConfig } from "@cubejs-client/core";
import { formatAbbreviatedNumber, formatCurrencyUSD, formatMonthYear, formatPercentage } from "data/formatters";
import { FormatConfig, LabelsConfig, TCubeQuery } from "data/types";

export const title: string = "Metric Trend";

export const query: TCubeQuery = {
  measures: [
    "campaign_report.impressions",
    "campaign_report.clicks",
    "campaign_report.conversions",
    "campaign_report.spend",
    "campaign_report.cpa",
    "campaign_report.cvr",
  ],
  timeDimensions: [
    {
      dimension: "campaign_report.date",
      granularity: "month",
    },
  ],
  order: {
    "campaign_report.date": "asc",
  },
};

export const pivotConfig: PivotConfig = {
  x: ["campaign_report.date"],
  y: ["measures"],
  fillMissingDates: true,
};

export const formatConfig: FormatConfig = {
  axis: {
    "campaign_report.date": (value) => formatMonthYear(value),
  },
  any: {
    "campaign_report.spend": (value) => formatCurrencyUSD(value),
    "campaign_report.cpa": (value) => formatCurrencyUSD(value),
    "campaign_report.cvr": (value) => formatPercentage(value),
    "campaign_report.conversions": (value) => formatAbbreviatedNumber(value, 0),
    "campaign_report.impressions": (value) => formatAbbreviatedNumber(value, 0),
    "campaign_report.clicks": (value) => formatAbbreviatedNumber(value, 0),
  },
};

export const labelsConfig: LabelsConfig = {
  "campaign_report.spend": "Cost",
}

export const filteredBy = ["campaign_report.platform"];
