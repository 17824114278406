import * as React from "react";
import { Chart as ChartJS, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bubble } from "react-chartjs-2";
import { ICubeWidgetComponentProps } from "./types";
import { TCubeMeasures } from "data/types";
import { pivotConfig } from "data/sources/budget-cost";
import utils from "components/cube/utils";
import { useEffect, useMemo, useState } from "react";
import { useCubeDashboardContext } from "components/cube/contexts/dashboard-context";
import { useColors } from "./hooks/useColors";

ChartJS.register(Title, Tooltip, Legend);

type Dataset = { data: (BubbleType | null)[]; label: string; backgroundColor: string };

type BubbleType = { x: number; y: number; r: number; value: number };

const calculateBufferValue = (data: (BubbleType | null)[], property: "x" | "y") => {
  const minPropertyValue = Math.min(...data.map((point) => (point ? point[property] : 0)));
  const maxPropertyValue = Math.max(...data.map((point) => (point ? point[property] : 0)));
  return 10 + Math.abs(maxPropertyValue - minPropertyValue) * 0.5; // Adjust the multiplier as needed
};

const calculateNormalizationFactor = (datasets: Dataset[]) => {
  let minR = Number.MAX_VALUE;
  let maxR = Number.MIN_VALUE;

  datasets.forEach((dataset) => {
    dataset.data.forEach((point) => {
      minR = Math.min(minR, point?.r ?? 0);
      maxR = Math.max(maxR, point?.r ?? 0);
    });
  });

  return { minR, maxR };
};

const convertSeriesToBubbles = (
  item: { [key: string]: any },
  measures: TCubeMeasures
): BubbleType | null => {
  // Check if the array has 3 items
  if (!measures || measures.length !== 3) {
    throw Error(
      "Bubble chart widget queries are required to have 3 measures exactly (representing X, Y and R values for the bubble respectively)."
    );
  }

  const x = item[measures[0]] ?? 0;
  const y = item[measures[1]] ?? 0;
  const r = item[measures[2]] ?? 0;

  return { x, y, r, value: r };
};

export interface IBubbleChartWidget extends ICubeWidgetComponentProps {
  sharedColors?: boolean;
}

const BubbleChartWidget: React.FunctionComponent<IBubbleChartWidget> = ({
  dataSource,
  resultSet,
  sharedColors,
}) => {
  const measures = useMemo(() => dataSource.getMeasures(), [dataSource]);
  const colors = useColors(utils.labels.fromResultSet(dataSource, resultSet), sharedColors);

  try {
    if ((dataSource.pivotConfig?.x ?? []).length < 1) {
      throw Error("Bubble chart widget queries must be pivoted over one field.");
    }

    if ((dataSource.pivotConfig?.x ?? []).length > 1) {
      throw Error("Bubble chart widget queries cannot be pivoted over multiple fields.");
    }

    const datasets = resultSet.tablePivot(dataSource.pivotConfig).map((item, i) => {
      const pivotField = Object.keys(item).find(
        (k) => !measures?.includes(k)
      );
      const label = pivotField
        ? (
            utils.formatting.getDataSourceFieldFormatter(dataSource, pivotField, "axis") ??
            ((val: string) => val)
          )(String(item[pivotField]))
        : String(i);

      return {
        label,
        data: [convertSeriesToBubbles(item, measures)],
        backgroundColor: colors[i],
      };
    });

    const normalizationFactor = calculateNormalizationFactor(datasets);
    // Calculate buffer values for x and y based on the range of values in datasets
    const xBuffer: number = calculateBufferValue(
      datasets.flatMap((dataset) => dataset.data),
      "x"
    );
    const yBuffer: number = calculateBufferValue(
      datasets.flatMap((dataset) => dataset.data),
      "y"
    );

    datasets.forEach((dataset) => {
      dataset.data.forEach((point) => {
        if (point) {
          const normalizedR =
            (point.r - normalizationFactor.minR) /
            Math.max(normalizationFactor.maxR - normalizationFactor.minR, 1);
          point.r = 30 + normalizedR * 10; // Adjust the scaling factor as needed
        }
      });
    });

    const labels = utils.labels.fromResultSet(dataSource, resultSet);

    const titles = resultSet.tableColumns(pivotConfig).reduce((obj, col) => {
      obj[col.key] = (dataSource.labelsConfig ?? {})[col.key]
        ? dataSource.labelsConfig![col.key]
        : col.shortTitle;
      return obj;
    }, {} as { [field: string]: string });


    return (
      <Bubble
        data={{ labels, datasets }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              formatter: (value: BubbleType, context) => {
                const formatter = utils.formatting.getDataSourceFieldFormatter(
                  dataSource,
                  measures![1], // Using Y (0: X, 1: Y, 2: R)
                  "datalabels"
                );
                const label = formatter ? formatter(value.y) : `${value.y}`;
                return `${label}`;
              },
              color: "#fff", // Label text color
            },
            tooltip: {
              enabled: true,
              mode: "dataset",
              callbacks: {
                title: (tooltipItems) => {
                  return tooltipItems[0].dataset.label;
                },
                label: (tooltipItem) => {
                  const raw = tooltipItem.raw as BubbleType;
                  var lines: string[] = [];

                  measures?.forEach((measure, index) => {
                    const formatter = utils.formatting.getDataSourceFieldFormatter(
                      dataSource,
                      measure, // Using (0: X, 1: Y, 2: R, 3: value)
                      "tooltip"
                    );
                    index = index === 2 ? 3 : index; // Adjust index for the normalized radius value (e.g. using (0: X, 1: Y, 2: R, 3: value))
                    const label = titles[measure]; // Using (0: X, 1: Y, 2: R, 3: value)
                    const value = formatter ? formatter(Object.values(raw)[index]) : Object.values(raw)[index];
                    lines.push(`${label}: ${value}`);
                  });

                  return lines.join(", ");
                },
              },
            },
            legend: {
              position: "bottom",
              labels: {
                padding: 30,
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: titles[measures![0]], // Using X (0: X, 1: Y, 2: R),
              },
              type: "linear",
              position: "bottom",
              min: Math.floor(
                Math.min(
                  ...datasets.flatMap((dataset) => dataset.data.map((point) => point?.x ?? 0))
                ) - xBuffer
              ),
              max: Math.ceil(
                Math.max(
                  ...datasets.flatMap((dataset) => dataset.data.map((point) => point?.x ?? 0))
                ) + xBuffer
              ),
              ticks: {
                callback: function (value: any, index: any, values: any) {
                  const formatter = utils.formatting.getDataSourceFieldFormatter(
                    dataSource,
                    measures![0], // Using X (0: X, 1: Y, 2: R)
                    "axis"
                  );
                  return formatter ? formatter(value) : value;
                },
              },
              border: {
                dash: [1, 3],
              },
              grid: {
                drawTicks: false,
              },
            },
            y: {
              title: {
                display: true,
                text: titles[measures![1]], // Using Y (0: X, 1: Y, 2: R),
              },
              type: "linear",
              position: "left",
              min: Math.floor(
                Math.min(
                  ...datasets.flatMap((dataset) => dataset.data.map((point) => point?.y ?? 0))
                ) - yBuffer
              ),
              max: Math.ceil(
                Math.max(
                  ...datasets.flatMap((dataset) => dataset.data.map((point) => point?.y ?? 0))
                ) + yBuffer
              ),
              ticks: {
                callback: function (value: any, index: any, values: any) {
                  const formatter = utils.formatting.getDataSourceFieldFormatter(
                    dataSource,
                    measures![1], // Using Y (0: X, 1: Y, 2: R)
                    "axis"
                  );
                  return formatter ? formatter(value) : value;
                },
              },
              border: {
                dash: [1, 3],
              },
              grid: {
                drawTicks: false,
              },
            },
          },
        }}
        // @ts-ignore
        plugins={[ChartDataLabels]}
      />
    );
  } catch (e: any) {
    return <>Error: {(e as Error).message}</>;
  }
};

export default BubbleChartWidget;
