import * as React from "react";
import { ReactChild, useContext, useState } from "react";
import classNames from "classnames";
import ListingHeader from "components/cards/listing-header";
import ListingFooter from "components/cards/listing-footer";
import { listingContext, ListingType } from "components/cards/listing-context";
import Skeleton from "react-loading-skeleton";

interface IListingProps {
  children?: ReactChild | ReactChild[];
  selectionHandler: (selection: any[]) => void;
  deleteHandler: (items: any[]) => void;
  selectAllHandler: (shouldSelectAll: boolean) => void;
}

const Listing: React.FunctionComponent<IListingProps> = ({
  children,
  deleteHandler,
  selectionHandler,
  selectAllHandler,
}) => {
  const [type, setType] = useState(ListingType.grid);
  const listing = useContext(listingContext); // TODO: should be refactored into the new context pattern
  listing.type = type;

  return (
    <>
      <ListingHeader
        selectionHandler={selectionHandler}
        typeChangeHandler={(type: ListingType) => {
          setType(type);
        }}
        deleteHandler={deleteHandler}
        selectAllHandler={selectAllHandler}
      />
      <div
        className={classNames([
          "c-listing",
          "o-stack-2@sm",
          { "c-listing--grid": type === ListingType.grid },
          { "o-stack-3@lg": type === ListingType.grid },
          { "o-stack-4@xl": type === ListingType.grid },
          { "c-listing--block@md": type === ListingType.list },
          { "o-stack-1@md": type === ListingType.list },
        ])}
      >
        {listing.data?.data ? children : <CardSkeleton count={5} />}
      </div>
      <ListingFooter />
    </>
  );
};

export default Listing;

interface ICardSkeleton {
  count: number;
}

const CardSkeleton = ({ count }: ICardSkeleton) => {
  const cards = [];
  for (let index = 0; index < count; index++) {
    cards[index] = (
      <div className="c-listing__item" key={"card_" + index}>
        <div className="c-card">
          <div className="c-card__body">
            <div className="c-card__content">
              <Skeleton height={180} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <>{cards}</>;
};
