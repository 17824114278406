import { types } from 'mobx-state-tree';

import api from 'api';
import Tokens from './tokens';
import Errors from './errors';
import config from 'config';

export enum LoginStatus {
  LoggedOut = 'logged-out',
  LoggingIn = 'logging-in',
  LoggedIn = 'logged-in',
  LoggingOut = 'logging-out',
}

export const RootStore = types
  .model('RootStore', {
    loginStatus: types.optional(
      types.enumeration<LoginStatus>('loginStatus', [
        LoginStatus.LoggedOut,
        LoginStatus.LoggingIn,
        LoginStatus.LoggedIn,
        LoginStatus.LoggingOut,
      ]),
      LoginStatus.LoggingIn
    ),
    tokens: Tokens,
    errors: Errors,
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.loginStatus === LoginStatus.LoggedIn;
    },
    get isLoggedOut() {
      return self.loginStatus === LoginStatus.LoggedOut;
    },
    get isLoggingIn() {
      return self.loginStatus === LoginStatus.LoggingIn;
    },
    get isLoggingOut() {
      return self.loginStatus === LoginStatus.LoggingOut;
    },
  }))
  .actions((self) => ({
    setLoginStatus(status: LoginStatus) {
      self.loginStatus = status;
    },
  }))
  .actions((self) => ({
    init: () => {
      self.setLoginStatus(LoginStatus.LoggingIn);
      self.tokens.restoreTokens();
      if (self.tokens.hasToken) {
        self.setLoginStatus(LoginStatus.LoggedIn);
      } else {
        self.setLoginStatus(LoginStatus.LoggedOut);
      }
    },
    login: async ( data: { username: string, password: string }) => {
      self.setLoginStatus(LoginStatus.LoggingIn);
      try {
        const response = await api.auth.getToken({
          client_id: config.API_CLIENT_ID,
          client_secret: config.API_CLIENT_SECRET,
          grant_type: 'password',
          ...data
          }).fetch();

        self.tokens?.setAccessToken(response.access_token);
        self.tokens?.setRefreshToken(response.refresh_token);
        self.setLoginStatus(LoginStatus.LoggedIn);

        return true;
      } catch (error) {
        console.error(error)
        self.setLoginStatus(LoginStatus.LoggedOut);
        return false;
      }
    },
    logout: async () => {
        self.tokens?.setAccessToken(null);
        self.tokens?.setRefreshToken(null);
      self.setLoginStatus(LoginStatus.LoggedOut);
    },
  }))

export default RootStore;
