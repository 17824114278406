import * as React from 'react';
import { useFormikContext } from 'formik';
import Button, { CustomButtonProps } from 'components/button';
import { ReactNode, useEffect } from 'react';

type SubmitButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> &
  CustomButtonProps;

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  disabled,
  'aria-disabled': ariaDisabled,
  ...buttonProps
}) => {
  const { isSubmitting } = useFormikContext();
  const [child, setChild] = React.useState<ReactNode>(children);

  useEffect(() => {
    if (isSubmitting) {
      setChild(<>Loading...</>);
    } else {
      setChild(children);
    }
  }, [isSubmitting, children])

  return (
    <Button
      {...buttonProps}
      type="submit"
      disabled={isSubmitting || disabled}
      aria-disabled={isSubmitting || ariaDisabled}
    >
      {child}
    </Button>
  );
};
