import * as React from 'react';

import classNames from 'classnames';
import { ListingType, listingContext } from 'components/cards/listing-context';
import { useContext, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string'

// Icons
import { ReactComponent as IconGrid } from 'assets/svg/grid.svg';
import { ReactComponent as IconList } from 'assets/svg/list.svg';
import { SelectBoxControl } from 'components/controls/select-box-control';
import DeleteConfirmationAlert, { DeleteConfirmationAlertType } from 'components/form/delete-confirmation';


interface IListingHeaderProps {
  typeChangeHandler: (type: ListingType) => void,
  selectionHandler: (selection: number[]) => void,
  selectAllHandler: (shouldSelectAll: boolean) => void,
  deleteHandler: (items: number[]) => void,
}

const ListingHeader: React.FunctionComponent<IListingHeaderProps> = ({
  typeChangeHandler,
  deleteHandler,
  selectAllHandler
}) => {
  const listing = useContext(listingContext);
  let location = useLocation();
  let navigate = useNavigate();
  let currentSortOption = getCurrentSortOption();
  let selectBoxSortOptions = listing.sort_options?.map((option) => { return { value: option.key + ',' + option.direction, label: option.title } });
  const [isDeleting, setIsDeleting] = useState(false);


  function arrayCount(array: any[]) {
    return Object.keys(array).length
  }

  function changeSort(option: string) {
    let params = queryString.parse(location.search);
    let sort = option.split(',')[0];
    let direction = option.split(',')[1];
    navigate(location.pathname + '?' + queryString.stringify({ ...params, sort, direction }));
  }

  function getCurrentSortOption() {
    let params = queryString.parse(location.search);
    if (!params.sort && listing.sort_options && listing.sort_options?.length > 0) {
      return listing.sort_options[0].key + ',' + listing.sort_options[0].direction;
    }
    return params.sort + ',' + params.direction;
  }

  function isSelectAllChecked() {
    let isChecked = false;
    if (listing.selection && listing.data) {
      isChecked = arrayCount(listing.selection) === arrayCount(listing.data.data as any[]);
    }
    return isChecked;
  }

  function selectAllClicked() {
    selectAllHandler(!isSelectAllChecked());
  }

  return (
    <div>


      <div className="o-row o-row--fluid c-listing__header u-justify-between u-items-center">
        <div className="o-col">
          <div className="c-actions">
            <div className="c-actions__col">
              <form>
                <div className="c-form-element c-form-element--style-line c-form-element--checkbox  c-form-element--checkbox--basic">
                  <div className="c-form-element__field">
                    <input onClick={selectAllClicked} type="checkbox" id="check-item-31" checked={isSelectAllChecked()} readOnly />
                    <label htmlFor="check-item-31"></label>
                  </div>
                </div>
              </form>
            </div>
            <div className="c-actions__col c-actions__col--no-divider">
              {listing.selection && arrayCount(listing.selection) > 0 ? (
                <p className="c-caption">{arrayCount(listing.selection)} selected {arrayCount(listing.selection) === 1 ? 'entry' : 'entries'}</p>
              ) : (
                <p className="c-caption">No selected entries</p>
              )}
            </div>
            {listing.selection && arrayCount(listing.selection) > 0 ? (
              <div className="c-actions__col">
                <div onClick={(event) => { event.preventDefault(); setIsDeleting(true); }} className="c-link-cta-light"><span>Delete</span></div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="o-col">
          <div className="c-actions">
            <SelectBoxControl
              title={'sort by:'}
              subtitle={'Select sort order:'}
              options={selectBoxSortOptions}
              currentValue={currentSortOption}
              onChange={(selectedValue) => {
                changeSort(selectedValue);
              }}
            />

            <div className="c-actions__col u-hidden u-flex@md">
              <div
                onClick={(event) => typeChangeHandler(ListingType.grid)}
                className={classNames([
                  'c-actions__icon c-actions__nav',
                  { 'is-selected': listing.type === ListingType.grid }
                ])}>
                <IconGrid className="o-svg-icon" />
              </div>
              <div
                onClick={(event) => typeChangeHandler(ListingType.list)}
                className={classNames([
                  'c-actions__icon c-actions__nav',
                  { 'is-selected': listing.type === ListingType.list }
                ])}>
                <IconList className="o-svg-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationAlert
        onDelete={() => { deleteHandler(listing.selection!); setIsDeleting(false); }}
        onCancel={() => { setIsDeleting(false) }}
        resource_label={arrayCount(listing.selection!) + ' selected ' + (arrayCount(listing.selection!) === 1 ? 'entry' : 'entries')}
        show={isDeleting}
        type={DeleteConfirmationAlertType.Global}
      />
    </div>
  );
};

export default ListingHeader;
