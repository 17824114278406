import * as cube from './endpoints/cube';
import * as auth from './endpoints/auth';
import * as groups from './endpoints/groups';
import * as users from './endpoints/users';
import * as dashboards from './endpoints/dashboards';
import * as files from './endpoints/files';
import * as password from './endpoints/password';

const api = {
  cube,
  auth,
  groups,
  users,
  dashboards,
  files,
  password,
};

export default api;
