import api from 'api';
import useRequest from 'api/use-request';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { DashboardNavigation } from './dashboard-nav';

export interface ICubeDashboardTitleProps {
}

const DashboardTitle: React.FunctionComponent<ICubeDashboardTitleProps> = (props) => {
  let { id } = useParams() as {
    id: string,
  };

  const dashboardEntry = useRequest(api.dashboards.getDashboard({
    id: id,
  }));

  if (dashboardEntry.data) {
    return (
      <div className="c-page-title">
        <DashboardNavigation groupId={dashboardEntry.data?.group_id!} />
        <h2 className="c-title--large">{ dashboardEntry.data?.name }</h2>
      </div>
    );
  } else {
    return (
      <div className="c-page-title">
        <h2 className="c-title--large"><Skeleton height={50} width={500} /></h2>
      </div>
    );
  }

};

export default DashboardTitle;
