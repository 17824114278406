import { PivotConfig } from "@cubejs-client/core";
import { formatAbbreviatedNumber, formatStartCase } from "data/formatters";
import { FormatConfig, TCubeQuery } from "data/types";

export const title: string = "Conversions";

export const query: TCubeQuery = {
  measures: ["campaign_report.conversions"],
  timeDimensions: [
    {
      dimension: "campaign_report.date",
    },
  ],
  dimensions: ["campaign_report.platform"],
};

export const pivotConfig: PivotConfig = {
  x: ["campaign_report.platform"],
  y: ["measures"],
};

export const formatConfig: FormatConfig = {
  any: {
    "campaign_report.conversions": (value) => formatAbbreviatedNumber(value, 0),
    "campaign_report.platform": (value) => formatStartCase(value),
  },
};

export const filteredBy = ["campaign_report.platform", "campaign_report.date"];
