import { Field as FormikField, FieldConfig, useFormikContext } from 'formik';
import { useEffect } from 'react';
import classNames from 'classnames';

import ErrorMessage from 'components/form/error-message';
import React from 'react';
import { FormFieldVariant, FormFieldSizing } from 'components/form/common';

export type CheckboxFieldProps<T> = Omit<JSX.IntrinsicElements['input'], 'value'> &
  Omit<FieldConfig<T>, 'component' | 'as' | 'render' | 'children' | 'type'> & {
    name: string;
  } & {
    label?: React.ReactNode;
    variant?: FormFieldVariant;
    sizing?: FormFieldSizing;
    validateOnInit?: boolean;
    options: Record<string, React.ReactNode> | React.ReactNode | string;
    required?: boolean;
    help?: string;
  };

export const CheckboxField = <T extends any>({
  label,
  variant,
  sizing,
  name,
  validateOnInit,
  options,
  disabled,
  required = false,
  help,
  ...formikFieldProps
}: CheckboxFieldProps<T>): React.ReactElement => {
  const { setFieldTouched, isSubmitting, getFieldMeta } = useFormikContext<any>();
  const fieldMeta = getFieldMeta(name);
  const errorMsg = fieldMeta.error;
  const hasError = typeof errorMsg !== 'undefined' && fieldMeta.touched;

  useEffect(() => {
    if (validateOnInit) {
      setFieldTouched(name, true, true);
    }
  }, [validateOnInit, setFieldTouched, name]);


  return (
    <div className={classNames([
      'c-form-element',
      'c-form-element--checkbox',
      {
        ['c-form-element' + variant]: variant,
        'c-form-element--error': hasError
      }
    ])}>
      {label && (
        <label className="c-form-label">{label}</label>
      )}
      <div className="c-form-element__field" key={name}>
        <FormikField
          {...formikFieldProps}
          name={name}
          type="checkbox"
          id={name}
          disabled={isSubmitting ? true : disabled}
        />
        {options && (
          <label htmlFor={name}>{options}</label>
        )}
      </div>
      <ErrorMessage name={name} />
      {help && (
        <p className="c-note">{help}</p>
      )}
    </div>
  );
};
