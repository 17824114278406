import * as React from 'react';
// Icons
import { ReactComponent as IconAdd } from 'assets/svg/add.svg';

// Components
import GroupCard from 'components/cards/group';
import Listing from 'components/cards/listing';
import { listingContext, ListingSortOption, SortDirection } from 'components/cards/listing-context';

import api from 'api';
import useRequest from 'api/use-request';
import { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import queryString from 'query-string';
import { PageContext } from 'components/layout/page-context';

const GroupsIndexPage: React.FunctionComponent = () => {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle('Manage Clients');
    page.setBackLink();
    page.setShowsGroupSwitcher(false);
  });

  let navigate = useNavigate();
  let location = useLocation();
  let newGroupLink = '/groups/new';

  let searchArgs = queryString.parse(location.search, { parseNumbers: true });
  if (searchArgs.limit === undefined) {
    searchArgs.limit = 12;
  }

  let [selection, setSelection] = useState<string[]>([]);

  let groups = useRequest(api.groups.getGroups(searchArgs));

  let sort_options: Array<ListingSortOption> = [
    { key: 'name', title: 'Name, Ascending', direction: SortDirection.Ascending },
    { key: 'name', title: 'Name, Descending', direction: SortDirection.Descending },
    { key: 'created_at', title: 'Created At, Ascending', direction: SortDirection.Ascending },
    { key: 'created_at', title: 'Created At, Descending', direction: SortDirection.Descending },
    { key: 'users_count', title: 'Users Count, Ascending', direction: SortDirection.Ascending },
    { key: 'users_count', title: 'Users Count, Descending', direction: SortDirection.Descending },
    { key: 'dashboards_count', title: 'Dashboards Count, Ascending', direction: SortDirection.Ascending },
    { key: 'dashboards_count', title: 'Dashboards Count, Descending', direction: SortDirection.Descending },
  ];

  function deleteGroups(ids: string[]) {
    try {
      ids.forEach((id) => api.groups.deleteGroup({ id }).fetch())
    } catch (error) {
      // TODO: handle error
      console.log(error);
    }
    groups.mutate();
  }

  function toggleSelectGroups(ids: string[]) {
    ids.forEach((id) => {
      const index = selection.indexOf(id);
      if (index > -1) {
        selection.splice(index, 1);
      } else {
        selection.push(id);
      }
    });
    setSelection([...selection]);
  }

  function selectAll(shouldSelect: boolean) {
    if (groups.data && groups.data?.data.length > 0) {
      groups.data?.data.forEach((group) => {
        const index = selection.indexOf(group.id);
        if (shouldSelect && index < 0) {
          selection.push(group.id);
        }
        if (!shouldSelect && index > -1) {
          selection.splice(index, 1);
        }
      });
      setSelection([...selection]);
    }
  }

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">All Clients</h2>
          <div onClick={(e) => { e.preventDefault(); navigate(newGroupLink) }} className="c-button c-button--stroke c-button--primary c-button--icon u-hidden@md"><IconAdd className="o-svg-icon" /></div>
          <div onClick={(e) => { e.preventDefault(); navigate(newGroupLink) }} className="c-button c-button--stroke c-button--primary u-hidden u-block@md"><IconAdd className="o-svg-icon" /><span>Add new client</span></div>
        </div>
        <listingContext.Provider value={{ ...groups, selection, sort_options }}>
          <Listing
            deleteHandler={deleteGroups}
            selectionHandler={toggleSelectGroups}
            selectAllHandler={selectAll}
          >
            {groups.data?.data.map((group) =>
              <GroupCard
                deleteHandler={deleteGroups}
                selectHandler={toggleSelectGroups}
                key={group.id}
                group={group}
              />
            )}
          </Listing>
        </listingContext.Provider>
      </div>
    </section>
  );
};

export default GroupsIndexPage;
