export enum FormFieldVariant {
  'line' = '--style-line',
  'fill' = '--style-fill'
}

export enum FormFieldSizing {
  'small' = '--small',
  'high' = '--high',
  'large' = '--large'
}
