import * as React from 'react';

import { ReactComponent as IconDropdown } from 'assets/svg/dropdown.svg';
import { TabsNav, TabsPanel } from 'components/layout/tabs';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import GroupGeneralTab from 'components/tabs/group-general';
import DashboardsTab from 'components/tabs/dashboards';
import UsersTab from 'components/tabs/users';
import useRequest from 'api/use-request';
import api from 'api';
import { useContext, useEffect } from 'react';
import { PageContext } from 'components/layout/page-context';

export interface IGroupPageProps {
  createNew?: boolean;
}

export interface IGroupUrlParams {
  id: string;
}


export default function GroupPage({ createNew = false }: IGroupPageProps) {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle('Edit Client');
    page.setBackLink('/groups');
    page.setShowsGroupSwitcher(false);
  });

  let params = useParams() as {
    groupId: string | undefined,
  };
  let groupId = params.groupId ?? '';

  let group = useRequest(api.groups.getGroup({ id: groupId }));

  const tabs = [
    { label: 'General', path: 'general' },
    { label: 'Dashboards', path: 'dashboards' },
    { label: 'Users', path: 'users' },
  ];

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">{group.data?.name}</h2>
          {group.data?.first_dashboard_id &&
            <Link to={`/dashboard/` + group.data.first_dashboard_id} className="c-link-cta"><span>View client</span><IconDropdown className="o-svg-icon o-svg-right o-svg-small" /></Link>
          }
        </div>

        <TabsNav tabs={tabs}>
          <Routes>
            <Route path={tabs[0].path} element={
              <TabsPanel>
                <GroupGeneralTab groupId={groupId} isNew={createNew} />
              </TabsPanel>
            } />
            <Route path={tabs[1].path} element={
              <TabsPanel>
                <DashboardsTab groupId={groupId} />
              </TabsPanel>
            } />
            <Route path={tabs[2].path} element={
              <TabsPanel>
                <UsersTab groupId={groupId} />
              </TabsPanel>
            } />
          </Routes>
        </TabsNav>
      </div>
    </section>
  );
}
