import { FormatConfig, LabelsConfig, SubtotalConfig, TCubeQuery } from "data/types";
import { formatCurrencyUSD } from "data/formatters";
import {
  title,
  pivotConfig,
  formatConfig as originalFormatConfig,
  subtotalConfig as originalSubtotalConfig,
  filteredBy as originalFilteredBy,
} from "data/sources/campaign-performance-breakdown";
import utils from "components/cube/utils";

export {
  title,
  pivotConfig,
};

export const query: TCubeQuery = {
  measures: [
    "campaign_report.impressions",
    "campaign_report.clicks",
    "campaign_report.conversions",
    "campaign_report.conversions_value",
    "campaign_report.spend",
    "campaign_report.roas",
    "campaign_report.cpa",
    "campaign_report.cvr",
    "campaign_report.cpc",
    "campaign_report.ctr",
  ],
  timeDimensions: [
    {
      dimension: "campaign_report.date",
    },
  ],
  order: [
    ["campaign_report.platform", "asc"],
    ["campaign_report.campaign_name", "asc"],
  ],
  dimensions: ["campaign_report.platform", "campaign_report.campaign_name"],
  limit: 25,
};

export const formatConfig: FormatConfig = {
  any: {
    ...originalFormatConfig.any,
    "campaign_report.roas": (value) => formatCurrencyUSD(value),
    "campaign_report.conversions_value": (value) => formatCurrencyUSD(value),
  },
};

export const subtotalConfig: SubtotalConfig = {
  ...originalSubtotalConfig,
  "campaign_report.conversions_value": (rows) =>
    formatCurrencyUSD(utils.table.calculateFieldSubtotal(rows, "campaign_report.conversions_value")),
  "campaign_report.roas": (rows) => {
    const totalRevenue = utils.table.calculateFieldSubtotal(rows, "campaign_report.conversions_value");
    const totalCost = utils.table.calculateFieldSubtotal(rows, "campaign_report.spend");
    const roas = (totalCost !== 0) ? totalRevenue / totalCost : 0;
    return formatCurrencyUSD(roas);
  },
};

export const labelsConfig: LabelsConfig = {
  "campaign_report.spend": "Cost",
  "campaign_report.conversions": "Purchases",
  "campaign_report.conversions_value": "Revenue",
};

export const filteredBy = [ ...originalFilteredBy, "campaign_report.campaign_group_1", "campaign_report.campaign_group_2"];
