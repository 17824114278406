import * as React from "react";
import { ICubeWidgetComponentProps } from "./types";
import { useCubeDashboardContext } from "components/cube/contexts/dashboard-context";
import { useCubeQuery } from "@cubejs-client/react";
import { DateRange, ResultSet } from "@cubejs-client/core";
import { subMonths, format } from "date-fns";
import utils from "./utils";
import { useMemo, useState } from "react";
import { isDBQ } from "data/utils";
import { Query } from "@cubejs-client/core";

const getPrecedingDateRange = (daterange: DateRange): DateRange => {
  return [
    format(subMonths(Date.parse(daterange[0]), 1), "yyyy-MM-dd"),
    format(subMonths(Date.parse(daterange[1]), 1), "yyyy-MM-dd"),
  ];
};

const NumberWidget: React.FunctionComponent<ICubeWidgetComponentProps> = ({
  title,
  dataSource,
  resultSet,
}) => {

  const { filter } = useCubeDashboardContext();
  const [lastPrecedingResultSet, setLastPrecedingResultSet] = useState<ResultSet<any> | null>(
    null
  );


  const precedingDateRangeQuery = useMemo(() => {
    const query = dataSource.getQuery(filter)

    const adjustQueryTimeDimension = (query: Query): Query => {
      query.timeDimensions?.forEach((timeDimension) => {
        if (timeDimension.dateRange && filter[timeDimension.dimension]) {
          const filterDateRange = filter[timeDimension.dimension] as DateRange;

          timeDimension.dateRange = getPrecedingDateRange([
            filterDateRange[0],
            filterDateRange[1],
          ]);
        }
      });

      return query;
    }

    if (isDBQ(query)) {
      query.forEach((q) => adjustQueryTimeDimension(q));
    } else {
      adjustQueryTimeDimension(query);
    }

    return query;
  }, [dataSource, filter]);

  const {
    resultSet: precedingResultSet,
    isLoading: precedingIsLoading,
    error: precedingError,
  } = useCubeQuery(
    precedingDateRangeQuery,
  );

  React.useEffect(() => {
    if (precedingResultSet) {
      setLastPrecedingResultSet(precedingResultSet);
    }
  }, [precedingResultSet]);

  utils.suspense.handle(lastPrecedingResultSet, precedingIsLoading);

  if (!resultSet || !lastPrecedingResultSet || precedingError) {
    return null;
  }

  const dataset = resultSet.series().map((item) => {
    const formatter = utils.formatting.getDataSourceFieldFormatter(dataSource, item.key);
    const label = (dataSource.labelsConfig ?? {})[item.key]
      ? dataSource.labelsConfig![item.key]
      : item.shortTitle;
    const val = item.series.reduce((total, item) => total + item.value, 0);
    return [label, val, formatter ? formatter(val) : val];
  });

  const precedingDataset = lastPrecedingResultSet
    .series()
    .map((item) => {
      const label = (dataSource.labelsConfig ?? {})[item.key]
        ? dataSource.labelsConfig![item.key]
        : item.shortTitle;
      return [label, item.series.reduce((total, item) => total + item.value, 0)];
    });

  const change = precedingDataset.map((precedingPeriodItem, i) => {
    const currentPeriodItem = dataset[i];

    if (!currentPeriodItem) {
      return NaN;
    }

    if (precedingPeriodItem[1] === 0 && currentPeriodItem[1] > 0) {
      return -100; // TODO: ???
    }

    return ((currentPeriodItem[1] - precedingPeriodItem[1]) / precedingPeriodItem[1]) * 100;
  });

  return (
    <>
      {dataset.length === 1 && (
        <>
          <h4 className="u-mb-0 u-text-center">{title ?? dataSource.title}</h4>
          <h5 className="u-text-truncate u-text-center stat primary">{dataset[0][2]}</h5>
          <hr className="u-mb-spacer" />
          <div className="u-text-1 u-flex">
            <div className="u-text-truncate">MoM% Change in {dataset[0][0]}</div>
            <div className="u-flex-grow">:&nbsp;&nbsp;</div>
            {!isNaN(change[0]) ? (
              <div className="stat secondary">{change[0].toFixed(2)}%</div>
            ) : (
              <div className="stat secondary">N/A</div>
            )}
          </div>
        </>
      )}
      {dataset.length > 1 && (
        <>
          <h4 className="u-mb-0 u-text-center">{title ?? dataSource.title}</h4>
          <h5 className="u-text-truncate u-text-center stat primary">{dataset[0][2]}</h5>
          <hr className="u-mb-spacer" />
          <div className="u-text-1 u-flex">
            <div className="u-text-truncate">{dataset[1][0]}</div>
            <div className="u-flex-grow">:&nbsp;&nbsp;</div>
            <div className="stat secondary">{dataset[1][2]}</div>
          </div>
        </>
      )}
      {dataset.length === 0 && (
        <>
          <h4 className="u-mb-0 u-text-center">{title ?? dataSource.title}</h4>
          <h5 className="u-text-truncate u-text-center stat primary">-</h5>
          <hr className="u-mb-spacer" />
          <div className="u-text-1 u-flex">
            <div className="u-text-truncate">No data available</div>
            <div className="u-flex-grow">:&nbsp;&nbsp;</div>
            <div className="stat secondary">-</div>
          </div>
        </>
      )}
    </>
  );
};

export default NumberWidget;
