import * as React from 'react';
import NotificationsMenu from './notifications-menu';
import UserMenu from './user-menu';


// Icon
import { ReactComponent as IconArrow } from 'assets/svg/arrow.svg';
import { useContext } from 'react';
import { PageContext } from './page-context';
import { Link } from 'react-router-dom';
import { AppErrors } from './app-errors';
import { sizedGroupLogoImageURL } from 'utils/imgix-helpers';
import GroupsIndexPage from 'pages/groups';
import GroupsMenu from './groups-menu';

interface IHeaderProps {
  ref: React.Ref<HTMLElement>;
}

const Header: React.ForwardRefExoticComponent<IHeaderProps> = React.forwardRef((props, ref) => {
  const page = useContext(PageContext);

  return (
    <header id="site-header" className="c-site-header" ref={ref}>
      <div className="o-container-fluid o-container--full">
        <div className="c-site-header__wrapper">

          <div className="c-site-header__main">
            <AppErrors />
            <div className="c-site-header__headline">
              {page.backLink &&
                <p><Link to={page.backLink} className="c-link-cta-basic"><IconArrow className="o-svg-icon o-svg-left" /><span>Back</span></Link></p>
              }
              {page.showsGroupSwitcher ? (
                <GroupsMenu />
              ) : (
                <div>
                  {
                    page.logoURL ? (
                      <div className="c-site-header__logo">
                        <img src={sizedGroupLogoImageURL(page.logoURL)} />
                      </div>
                    ) : (
                      <p>{page.title}</p>
                    )
                  }
                </div>
              )
              }
            </div>
          </div>

          <div className="c-site-header__utility">
            <NotificationsMenu />
            <UserMenu />
          </div>

        </div>
      </div>

    </header >
  );
});

export default Header;
