import * as React from "react";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { ICubeWidgetComponentProps } from "./types";
import utils from "./utils";
import { useColors } from "components/cube/hooks/useColors";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

interface Dataset {
  key: string;
  data: any[];
  total: number;
  label: string;
  backgroundColor: string[];
}

export interface IPieChartWidget extends ICubeWidgetComponentProps {
  sharedColors?: boolean;
}

const PieChartWidget: React.FunctionComponent<IPieChartWidget> = ({
  dataSource,
  resultSet,
  sharedColors,
}) => {
  const labels = utils.labels.fromResultSet(dataSource, resultSet);
  const colors = useColors(labels, sharedColors);

  const datasets: Dataset[] = resultSet.series(dataSource.pivotConfig).map((item, i) => {
    const values = item.series.map((item) => item.value);

    return {
      key: item.key,
      data: values,
      total: values.reduce((acc: number, value: number) => acc + value, 0),
      label: (dataSource.labelsConfig ?? {})[item.key]
        ? dataSource.labelsConfig![item.key]
        : item.shortTitle,
      backgroundColor: colors,
      hoverBorderWidth: 4,
    };
  });

  return (
    <Doughnut
      data={{ labels, datasets }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        cutout: "60%",
        clip: {
          left: 20,
          top: 20,
          right: 20,
          bottom: 20,
        },
        layout: {
          padding: {
            top: 50,
            bottom: 50,
          },
        },
        plugins: {
          datalabels: {
            align: "end",
            display: "auto",
            offset: (ctx) => {
              return ctx.chart.height * 0.1;
            },
            font: {
              weight: "bold",
            },
            formatter: (value: number, context) => {
              const dataset = context.dataset as Dataset;
              const formatter = utils.formatting.getDataSourceFieldFormatter(
                dataSource,
                dataset.key,
                "datalabels"
              );
              const label = formatter ? formatter(value) : `${value}`;
              const percentage = (dataset.total !== 0)? ((value / dataset.total) * 100).toFixed(2) : 0;
              return `${percentage}%\n(${label})`;
            },
            // color: "#fff", // Label text color
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItem) => {
                const dataset = tooltipItem.dataset as Dataset;
                const formatter = utils.formatting.getDataSourceFieldFormatter(
                  dataSource,
                  dataset.key,
                  "tooltip"
                );
                const label = tooltipItem.label;
                const value = formatter ? formatter(tooltipItem.raw) : tooltipItem.formattedValue;
                return `${label}: ${value}`;
              },
            },
          },
          legend: {
            position: "left",
          },
        },
      }}
      // @ts-ignore
      plugins={[ChartDataLabels]}
    />
  );
};

export default PieChartWidget;
