import * as React from 'react';

import { useDropzone } from "react-dropzone";

import { ReactComponent as IconImage } from 'assets/svg/image.svg';
import { useEffect, useState } from 'react';


interface IFileDropzoneFieldProps {
  setFieldValue: any,
  dropHandler: any,
  name: string,
  shouldOpenBrowseDialog: boolean,
  description?: string;
}

const FileDropzoneField: React.FunctionComponent<IFileDropzoneFieldProps> = (props) => {
  const { setFieldValue, dropHandler } = props;
  let [dropError, setDropError] = useState<string>();
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    onDrop: acceptedFiles => {
      setDropError(undefined);
      setFieldValue(props.name, acceptedFiles);
      dropHandler(acceptedFiles);
    },
    onDropRejected: fileRejections => {
      if (fileRejections && fileRejections.length > 0) {
        setDropError(fileRejections[0].errors[0].message);
      }
    },
    maxFiles: 1,
    maxSize: 1 * 1000 * 1000,
  });

  useEffect(() => {
    if (props.shouldOpenBrowseDialog) {
      open();
    }
  }, [props.shouldOpenBrowseDialog, open]);

  return (
    <div {...getRootProps({ className: "dropzone c-add c-add--link" })}>
      <input {...getInputProps()} />
      <div className="c-add__icon">
        <IconImage className="o-svg-icon" />
      </div>
      <div className="c-add__body">
        <div className="c-add__title">
          {isDragActive ? (
            <p>Drop your image here ...</p>
          ) : (
              <p>Drop your image here, or <a href="#" onClick={(e) => { e.preventDefault(); }} className="c-link-cta c-add__link"><span>browse</span></a>.</p>
            )}

        </div>
        {dropError && (
          <ul className="c-form-element--error__list u-block">
            <li>{dropError}</li>
          </ul>
        )}
        {props.description && (
          <div className={`c-add__desc ${dropError ? "u-hidden" : ""}`}>
            <p>{props.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileDropzoneField;
