import classNames from 'classnames';
import * as React from 'react';

interface IColumnProps {
  children?: React.ReactNode;
  width: number;
}

const Column: React.FunctionComponent<IColumnProps> = ({ width, children }) => {
  return (
    <div className={classNames([`o-col-${width}@md`])}>
      {children}
    </div>
  );
};

export default Column;
