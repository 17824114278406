import React from "react";
import AdReportingDefault from "components/cube/dashboards/ad-reporting-default";
import AdReportingZildjian from "./ad-reporting-zildjian";
import AdReportingCatania from "./ad-reporting-catania";

export interface IClientAdReportingDashboardProps {
  client?: string;
}

export enum AdReportingClient {
  DATABANK = "client_databank",
  ZILDJIAN = "client_zildjian",
  CATANIA = "client_catania",
  CONSTRUCTOR = "client_constructor",
}

export default function ClientAdReportingDashboard({ client }: IClientAdReportingDashboardProps) {
  switch (client) {
    case AdReportingClient.DATABANK:
    case AdReportingClient.CONSTRUCTOR:
      return (
        <AdReportingDefault />
      );
    case AdReportingClient.CATANIA:
      return (
        <AdReportingCatania />
      );
    case AdReportingClient.ZILDJIAN:
      return (
        <AdReportingZildjian />
      );
    default:
      return (
        <div>Error in Ad Reporting Client identifier</div>
      )
  }
}
