import api from "api";
import { DashboardObject, DashboardType } from "api/endpoints/dashboards";
import useRequest from "api/use-request";
import classNames from "classnames";
import useClickAway from "hooks/use-click-away";
import * as React from "react";
import { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

export interface IDashboardNavigationProps {
  groupId: string;
}

export function DashboardNavigation({ groupId }: IDashboardNavigationProps) {
  let [isOpen, setIsOpen] = useState(false);
  let groupDashboards = useRequest(api.dashboards.getDashboards({ group_id: groupId }));
  let currentGroup = useRequest(api.groups.getGroup({ id: groupId }));

  let { id } = useParams() as {
    id: string | undefined;
  };

  function isCurrentDashboard(dashboardId: string) {
    return dashboardId === id;
  }

  function dashboardLink(dashboard: DashboardObject) {
    switch (dashboard.type) {
      case DashboardType.adreport:
        return (
          <Link onClick={() => setIsOpen(false)} to={`/dashboard/${dashboard.id}`}>
            {dashboard.name}
          </Link>
        );

      case DashboardType.url:
        return <Link to={dashboard.source}>{dashboard.name}</Link>;

      default:
        return null;
    }
  }

  const container = useRef<HTMLDivElement>(null);
  useClickAway(container, () => setIsOpen(false));

  function hasMultipleDashboards() {
    const list = groupDashboards.data?.data;
    if (list && list.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  if (hasMultipleDashboards()) {
    return (
      <div ref={container} className={classNames(["c-page-nav", { "has-popup-open": isOpen }])}>
        <div className="c-page-nav-toggle" onClick={() => setIsOpen(!isOpen)}>
          <span className="c-page-nav-toggle__text">
            <span>Dashboard menu</span>
            <span className="c-page-nav-toggle__text--active">Close menu</span>
          </span>
          <div className="c-page-nav-toggle__icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="c-page-nav__popup c-popup">
          <div className="c-popup__header c-popup__header--lg"></div>
          <div className="c-popup__body">
            <p className="c-popup__title">{currentGroup.data?.name}</p>
            <ul className="c-page-nav__menu">
              {groupDashboards.data?.data.map((dashboard, index) => (
                <li
                  key={index}
                  className={classNames([
                    {
                      "is-selected": isCurrentDashboard(dashboard.id),
                    },
                  ])}
                >
                  {dashboardLink(dashboard)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
