import { format, parseISO } from "date-fns";
import _ from "lodash";

const USD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatAbbreviatedNumber = (value: number, decimals?: number): string => {
  const absValue = Math.abs(value);


  if (absValue >= 1e6) {
    const formattedValue = (absValue / 1e6).toFixed(1);
    return value < 0 ? `-${formattedValue}M` : `${formattedValue}M`;
  } else if (absValue >= 1e3) {
    const formattedValue = (absValue / 1e3).toFixed(1);
    return value < 0 ? `-${formattedValue}K` : `${formattedValue}K`;
  } else {
    return Number(value).toFixed(decimals ?? 2);
  }
};

export const formatLocalizedInt = (value: any): string => parseInt(value).toLocaleString('en-US');

export const formatCurrencyUSD = (value: any): string => USD.format(value);

export const formatBudget = (value: any): string => value? formatCurrencyUSD(value) : "N/A";

export const formatPercentage = (value: any): string => `${parseFloat(value).toFixed(2)}%`;

export const formatQuarter = (value: any): string => format(parseISO(value), "yyyy QQQ");

export const formatMonthYear = (value: any): string => format(parseISO(value), "MM/yyyy");

export const formatTextualMonthYear = (value: any): string => format(parseISO(value), "MMMM yyyy");

export const formatStartCase = (value: any): string => {
  let result = _.startCase(value);
  result = result.replaceAll("Linkedin", "LinkedIn");
  return result;
};
