import * as React from 'react';

interface IFilePreviewProps {
  newHandler: any,
  newButtonLabel: string,
  removeHandler: any,
  removeButtonLabel: string,
  filePreviewUrl: string,
  descriptionLabel: string | undefined,
  compactLayout: boolean,
}

const FilePreview: React.FunctionComponent<IFilePreviewProps> = (props) => {
  return (
    <div className="o-row o-row--reverse c-image-edit">
      <div className={`${props.compactLayout ? "o-col-12@md" : "o-col-6@md"} u-self-start`}>
        <div className="c-image-edit__container">
          <img src={props.filePreviewUrl} alt="" />
        </div>
      </div>
      <div className={`${props.compactLayout ? "o-col-12@md" : "o-col-6@md"}`}>
        <div className="o-row o-row--fluid c-button-group c-button-group--row@sm u-justify-center u-justify-start@md">
          <div className="o-col">
            <div onClick={(e) => { e.preventDefault(); props.newHandler(); }} className="c-button c-button--stroke c-button--primary"><span>{props.newButtonLabel}</span></div>
          </div>
          <div className="o-col c-button-group__inline">
            <div onClick={(e) => { e.preventDefault(); props.removeHandler(); }} className="c-link-cta-basic"><span>{props.removeButtonLabel}</span></div>
          </div>
        </div>
        {props.descriptionLabel &&
          <p className="c-note">{props.descriptionLabel}</p>
        }
      </div>
    </div >
  );
};

export default FilePreview;
