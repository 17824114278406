import * as React from 'react';
import * as z from 'zod';

// Components
import { Form, FormNotification } from 'components/form/form';
import { InputField, InputFieldType } from 'components/form/input';
import { CheckboxField } from 'components/form/checkbox';
import { SubmitButton } from 'components/form/submit-button';

// Types
import { FormFieldVariant } from 'components/form/common';
import { ButtonScope } from 'components/button';

// Hooks
import { useEffect, useState } from 'react';
import { useStore } from 'store';
import { useNavigate, useLocation, Link } from "react-router-dom";

// Icons
import { Notification, NotificationTypes } from 'components/notification';
import useRequest from 'api/use-request';
import api from 'api';


// TODO: customize the copy depending for returning visitors/users
const LoginPage: React.FunctionComponent = () => {
  const store = useStore();
  let navigate = useNavigate();
  let location = useLocation();
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [didLogin, setDidLogin] = useState(false);

  const user = useRequest(api.users.getCurrentUser(), didLogin || store.isLoggedIn);
  const dashboards = useRequest(api.dashboards.getDashboards({ group_id: user.data?.groups[0].id }), didLogin || store.isLoggedIn);

  async function loginSubmit(data: { username: string, password: string }) {
    if (await store.login(data)) {
      setDidLogin(true);
      console.log(window.snowplow);
      if (window.snowplow) {
        window.snowplow('trackStructEvent', 'login', data.username, null, null, null);
      }
    } else {
      setHasLoginFailed(true);
    }
  }

  useEffect(() => {
    if (didLogin) {
      // Redirect to a known location
      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        window.loader(true);
      }
    }
  }, [didLogin, location.state, navigate])

  // Route the user to his homepage
  useEffect(() => {
    if (!user.data || !dashboards.data) return; // bail if we dont have these

    switch (user.data?.role) {
      case api.users.UserRoleTypes.SuperAdmin:
        navigate('/groups');
        break;
      case api.users.UserRoleTypes.GroupAdmin:
        navigate('/users');
        break;
      case api.users.UserRoleTypes.User:
        if (dashboards.data && dashboards.data?.data.length > 0) {
          // first dashboard for the user
          navigate('/dashboard/' + dashboards.data?.data[0].id);
        } else {
          navigate('/404');
        }
        break;

      default:
        navigate('/404');
        break;
    }

    window.loader(false);
  }, [user, dashboards, navigate, location.state])

  return (
    <div className="c-login__flow-wrapper">
      <div className="c-login__flow c-login__flow--visible">
        <div className="c-block__header">
          <h2>Welcome back!</h2>
          <p>Don't have a Reportiv account? Feel free to <a href="https://www.convertiv.com/contact/">contact us</a> for more details.</p>
        </div>
        {hasLoginFailed && (
          <Notification type={NotificationTypes.error}>
            <p>Incorrect username or password.</p>
          </Notification>
        )}
        <Form
          validationSchema={z.object({
            username: z.string().nonempty({ message: 'Email is incorrect' }).email({ message: 'Email is incorrect' }),
            password: z.string().nonempty({ message: 'Password is incorrect' }),
          })}
          initialValues={{ username: '', password: '' }}
          onSubmit={loginSubmit}
        >
          <FormNotification />
          <InputField
            type={InputFieldType.email}
            variant={FormFieldVariant.fill}
            name="username"
            autoComplete="username"
            placeholder="Your email"
          />
          <InputField
            type={InputFieldType.password}
            variant={FormFieldVariant.fill}
            name="password"
            autoComplete="current-password"
            placeholder="Password"
          />
          <CheckboxField
            variant={FormFieldVariant.fill}
            name="remember"
            options="Keep me logged in"
          />
          <div className="c-form__footer">
            <SubmitButton fullWidth scope={ButtonScope.primary}><span>Log in</span></SubmitButton>
          </div>
        </Form>
      </div>
      <div className="c-login__flow c-login__flow--secondary c-login__flow--visible">
        <div className="u-text-center">
          <p className="u-mb-spacer-base-small"><Link to="/password/forgot" className="c-link-cta-basic"><span>Forgot Password?</span></Link></p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
