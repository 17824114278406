import classNames from 'classnames';
import { Button, ButtonScope, ButtonSizing } from 'components/button';
import * as React from 'react';
import { ReactComponent as IconAlertQuestion } from 'assets/svg/alert-question.svg';

export enum DeleteConfirmationAlertType {
  Card,
  Global
}
interface IDeleteConfirmationAlertProps {
  onDelete: () => void,
  onCancel: () => void,
  resource_label: string,
  show: boolean,
  type: DeleteConfirmationAlertType
}

const DeleteConfirmationAlert: React.FunctionComponent<IDeleteConfirmationAlertProps> = (props) => {

  const onDeleteHandler = () => {
    props.onDelete();
  }

  const onCancelHandler = () => {
    props.onCancel();
  }

  const cardLayout = () => {
    return (
      <div className={classNames(['c-card__alert', 'c-card__alert--error', { 'u-hidden': !props.show }])}>
        <p className="c-card__alert-question">Are you sure you want to delete <strong>{props.resource_label}</strong>?</p>
        <div className="c-card__alert-options">
          <Button onClick={(e) => { e.preventDefault(); onDeleteHandler(); }} sizing={ButtonSizing.small} scope={ButtonScope.white} outline><span>Yes</span></Button>
          <Button onClick={(e) => { e.preventDefault(); onCancelHandler(); }} sizing={ButtonSizing.small} scope={ButtonScope.white} outline><span>No</span></Button>
        </div>
      </div>
    );
  }

  const globalLayout = () => {
    return (
      <div>
        <div className={classNames(['c-alert', 'c-alert--small@xs', 'c-alert--row@md', 'c-alert--error', 'c-color--invert', 'c-alert--anim', 'c-alert--no-close', 'c-alert--row-center', { 'c-alert--anim-hidden': !props.show }])}>
          <div className="c-alert__col c-alert__icon">
            <IconAlertQuestion className="o-svg-icon" />
          </div>
          <div className="c-alert__col c-alert__body">
            <p>Are you sure you want to delete <strong>{props.resource_label}</strong>?</p>
          </div>
          <div className="c-alert__col c-alert__footer">
            <Button onClick={(e) => { e.preventDefault(); onDeleteHandler(); }} sizing={ButtonSizing.small} scope={ButtonScope.white} outline><span>Yes</span></Button>
            <Button onClick={(e) => { e.preventDefault(); onCancelHandler(); }} sizing={ButtonSizing.small} scope={ButtonScope.white} outline><span>No</span></Button>
          </div>
        </div>
      </div>
    );
  }

  if (props.type === DeleteConfirmationAlertType.Global) {
    return globalLayout();
  } else {
    return cardLayout();
  }
};

export default DeleteConfirmationAlert;
