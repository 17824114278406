import * as React from "react";

import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconClose } from "assets/svg/close.svg";

import api from "api";
import {
  UserObject,
  UserFormSchema,
  UserFormObject,
  UserRoleTypes,
  allowedRolesForUser,
} from "api/endpoints/users";
import { GroupObject } from "api/endpoints/groups/index";
import { useEffect, useState } from "react";
import useRequest from "api/use-request";

import { Form, FormNotification } from "components/form/form";
import { FormFieldVariant } from "components/form/common";
import InputField, { InputFieldType } from "components/form/input";
import { SubmitButton } from "components/form/submit-button";
import SelectField from "components/form/select-field";
import FileDropzoneField from "components/form/file-dropzone-field";
import FilePreview from "components/form/file-preview";
import FileUploadIndicator from "components/cards/file-upload-indicator";

import { useNavigate, useParams } from "react-router-dom";

import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { sizedPreviewUserProfileImageURL } from "utils/imgix-helpers";
import { debug } from "utils/debug";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import * as quartzite from "quartzite";
import value from "*.ico";

import { Multiselect } from "components/controls/multiselect-control";
import { Field } from "formik";
import classNames from "classnames";
import { Notification, NotificationTypes } from "components/notification";

function formatDate(input: string) {
  const date = new Date(input);
  return quartzite.dateString(date);
}

export interface IUserDetailsProps {
  isNew: boolean;
  nextLocation: string;
  onUpdate: () => void;
  isProfile: boolean;
}

function newUserObj(groupId: string): UserFormObject {
  return _.clone({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    role: "user",
    new_password: "",
    new_password_confirm: "",
    profile_image: "",
    groups: [groupId],
  });
}

export function UserDetails({ isNew, nextLocation, onUpdate, isProfile }: IUserDetailsProps) {
  let [initialValue, setInitialValue] = useState<UserFormObject>();
  let [isUploading, setIsUploading] = useState(false);
  let [isDeleting, setIsDeleting] = useState(false);
  let [shouldOpenBrowseDialog, setShouldOpenBrowseDialog] = useState(false);
  let [uploadedFilePath, setUploadedFilePath] = useState("");
  let [uploadingFileName, setUploadingFileName] = useState("");
  let [editedUser, setEditedUser] = useState({} as UserObject);
  let currentUser = useRequest(api.users.getCurrentUser());
  let allGroups = useRequest(api.groups.getGroups({ page: 1, limit: 1000 }));
  let [errorResponse, setErrorResponse] = useState<string | undefined>();

  // require passwords when creating a new user, don't require for edits
  let validationSchema = UserFormSchema.omit({
    id: true,
    profile_image: true,
    profile_image_url: true,
    group_id: true,
  })
    .omit(isNew ? {} : { new_password: true, new_password_confirm: true })
    .nonstrict();

  let navigate = useNavigate();
  let { groupId, userId } = useParams() as {
    groupId: string | undefined;
    userId: string | undefined;
  };
  if (isProfile && currentUser.data) {
    groupId = currentUser.data.groups[0].id;
    userId = currentUser.data.id.toString();
  }

  const submitHandler = (values: any, formikHelpers: any) => {
    const payload: UserFormObject = {
      id: userId ? userId : "",
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role,
      new_password: values.new_password,
      new_password_confirm: values.new_password_confirm,
      profile_image: uploadedFilePath,
      groups: values.groups,
    };

    let update: Promise<UserObject>;
    if (isNew) {
      update = api.users.newUser(payload).fetch();
    } else {
      update = api.users.updateUser(payload, userId ?? "").fetch();
    }

    return update
      .then((response) => {
        setErrorResponse(undefined);
        navigate(nextLocation);
        onUpdate();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setErrorResponse(error.response.data.message);
        }
        console.log(error);
      });
  };

  const shouldShowGroupSelector = () => {
    return currentUser && currentUser.data?.role === UserRoleTypes.SuperAdmin && allGroups;
  };

  const shouldShowRolesSelector = () => {
    return (
      currentUser.data &&
      currentUser.data.role !== UserRoleTypes.User &&
      allowedRolesForUser(currentUser.data, editedUser).length > 0
    );
  };

  const loadUser = async (userId: string) => {
    let user = await api.users.getUser({ id: userId }).fetch();
    if (user) {
      setEditedUser(user);
      let groupsIds = user.groups.map((group) => {
        return group.id;
      });
      setInitialValue({
        new_password: "",
        new_password_confirm: "",
        profile_image: "",
        ...user,
        groups: groupsIds,
      });
    }
  };

  const initForm = () => {
    if (!userId) {
      if (isProfile && currentUser.data) {
        userId = currentUser.data.id.toString();
        setEditedUser(currentUser.data);
        let groupsIds = currentUser.data.groups.map((group) => {
          return group.id;
        });
        setInitialValue({
          new_password: "",
          new_password_confirm: "",
          profile_image: "",
          ...currentUser.data,
          groups: groupsIds,
        });
      } else {
        setInitialValue(newUserObj(groupId ?? ""));
      }
    } else {
      loadUser(userId);
    }
  };

  useEffect(initForm, []);

  useEffect(() => {
    document.body.classList.add("has-modal-open");

    return () => {
      document.body.classList.remove("has-modal-open");
    };
  });

  function uploadNewPicture() {
    let user = editedUser;
    user.profile_image_url = "*";
    setEditedUser(user);
    setShouldOpenBrowseDialog(true);
  }

  function removePicture() {
    setUploadedFilePath("*");
    let user = editedUser;
    user.profile_image_url = "*";
    setEditedUser(user);
  }

  function deleteEditedUser() {
    if (userId) {
      let update = api.users.deleteUser({ id: userId }).fetch();
      onUpdate();
      update
        .then((response) => {
          setIsDeleting(false);
          navigate(nextLocation);
        })
        .catch((error) => {
          setIsDeleting(false);
          debug(error);
        });
    }
  }

  return (
    <>
      {initialValue ? (
        <div className="c-modal__root">
          <div className="c-modal__wrapper">
            <div className="c-modal c-modal--overflow">
              <div className="c-modal__header">
                <div className="c-block c-block--spacing-t-extra-small">
                  <div className="o-container-fluid">
                    <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                      <div className="o-col">
                        <div
                          onClick={() => {
                            navigate(-1);
                          }}
                          className="c-link-cta-basic"
                        >
                          <span>Close</span>
                          <IconClose className="o-svg-icon" />
                        </div>
                      </div>
                      <div className="o-col u-flex-grow">
                        <p className="c-modal__headline">{isNew ? "Add" : "Edit"} user</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    {errorResponse && (
                      <div className="o-row">
                        <div className="o-col-12">
                          <Notification type={NotificationTypes.error}>
                            {errorResponse}
                          </Notification>
                        </div>
                      </div>
                    )}
                    <Form
                      validationSchema={validationSchema}
                      initialValues={initialValue}
                      onSubmit={submitHandler}
                      enableReinitialize
                    >
                      {({ values, errors, setFieldValue }) => (
                        <div className="o-row">
                          <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                            <FormNotification />
                            <div className="o-row">
                              <div className="o-col-6@lg o-col-5@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>Profile picture</h6>

                                  {isUploading ? (
                                    <FileUploadIndicator fileName={uploadingFileName} />
                                  ) : editedUser.profile_image_url &&
                                    editedUser.profile_image_url !== "*" ? (
                                    <FilePreview
                                      filePreviewUrl={sizedPreviewUserProfileImageURL(
                                        editedUser.profile_image_url
                                      )}
                                      newButtonLabel={"Upload new picture"}
                                      removeButtonLabel={"Remove picture"}
                                      descriptionLabel={undefined}
                                      newHandler={uploadNewPicture}
                                      removeHandler={removePicture}
                                      compactLayout={true}
                                    />
                                  ) : (
                                    <FileDropzoneField
                                      name={"profile_image"}
                                      shouldOpenBrowseDialog={shouldOpenBrowseDialog}
                                      setFieldValue={setFieldValue}
                                      dropHandler={(acceptedFiles: any[]) => {
                                        if (acceptedFiles.length > 0) {
                                          let formData = new FormData();
                                          let file = acceptedFiles[0];
                                          setUploadingFileName(file["name"]);
                                          formData.append("file", file);

                                          setIsUploading(true);
                                          api.files
                                            .uploadFile(formData, "images")
                                            .fetch()
                                            .then((response) => {
                                              let uploaded_file = response;
                                              setUploadedFilePath(uploaded_file.file_path);
                                              let user = editedUser;
                                              user.profile_image_url = uploaded_file.file_url;
                                              setEditedUser(user);
                                            })
                                            .catch((error) => {
                                              console.error(error);
                                            })
                                            .finally(() => {
                                              setIsUploading(false);
                                            });
                                        }
                                      }}
                                      description="Use a JPEG/PNG image no larger than 300x300 pixels."
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="o-col-6@lg o-col-7@xl">
                                <div className="u-mb-spacer-base-large">
                                  <h6>About</h6>
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`first_name`}
                                        placeholder="First Name"
                                        label="First Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`last_name`}
                                        placeholder="Last Name"
                                        label="Last Name"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-12">
                                      <InputField
                                        type={InputFieldType.text}
                                        name={`email`}
                                        placeholder="Email Address"
                                        label="Email Address"
                                        variant={FormFieldVariant.fill}
                                        autocomplete={false}
                                      />
                                    </div>
                                    {allGroups.data && shouldShowGroupSelector() ? (
                                      <div className="o-col-12">
                                        <label
                                          htmlFor={"groups"}
                                          className={classNames("c-form-label")}
                                        >
                                          Groups
                                        </label>
                                        <Field
                                          name="groups"
                                          id="groups"
                                          component={Multiselect}
                                          placeholder="Groups"
                                          options={allGroups.data.data.map((group: GroupObject) => {
                                            return { value: group.id, label: group.name };
                                          })}
                                          isMulti={true}
                                        />
                                        <br />
                                      </div>
                                    ) : (
                                      <div />
                                    )}

                                    {currentUser.data && shouldShowRolesSelector() ? (
                                      <div className="o-col-12">
                                        <SelectField
                                          name={`role`}
                                          placeholder="Role"
                                          label="User Role"
                                          variant={FormFieldVariant.fill}
                                        >
                                          {allowedRolesForUser(currentUser.data, editedUser).map(
                                            (role) => (
                                              <option key={role.role} value={role.role}>
                                                {role.title}
                                              </option>
                                            )
                                          )}
                                        </SelectField>
                                      </div>
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                </div>
                                <div className="u-mb-spacer-base-large">
                                  <h6>Change password</h6>
                                  <div className="o-row o-row--small-gutters">
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.password}
                                        name={`new_password`}
                                        placeholder="New Password"
                                        label="New Password"
                                        variant={FormFieldVariant.fill}
                                        required={false}
                                        autocomplete={false}
                                      />
                                    </div>
                                    <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                                      <InputField
                                        type={InputFieldType.password}
                                        name={`new_password_confirm`}
                                        placeholder="Reenter New Password"
                                        label="Reenter New Password"
                                        variant={FormFieldVariant.fill}
                                        required={false}
                                        autocomplete={false}
                                      />
                                    </div>
                                  </div>
                                  <p className="c-note">
                                    Your password must be at least 8 characters long, contain at
                                    least one number, one uppercase or lowercase letter and at least
                                    one special character.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                            <div className="c-card c-card--bg-blue">
                              <div className="c-card__body">
                                <div className="c-card__header">
                                  <h6>{isNew ? "Create" : "Update"}</h6>
                                  {!isNew && (
                                    <div className="c-card__desc">
                                      {editedUser && editedUser.updated_at ? (
                                        <p>
                                          This user was last updated{" "}
                                          {formatDate(editedUser.updated_at)}.
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="o-row o-row--fluid c-button-group">
                                  <div className="o-col">
                                    <SubmitButton disabled={isUploading}>
                                      <span>{isNew ? "Create" : "Update"} user</span>
                                    </SubmitButton>
                                  </div>
                                  {!isNew && (
                                    <div className="o-col c-button-group__inline">
                                      <div
                                        className="c-link-cta-basic"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setIsDeleting(true);
                                        }}
                                      >
                                        <IconTrash className="o-svg-icon" />
                                        <span>Delete account</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <DeleteConfirmationAlert
                                onDelete={deleteEditedUser}
                                onCancel={() => {
                                  setIsDeleting(false);
                                }}
                                resource_label={editedUser.first_name + " " + editedUser.last_name}
                                show={isDeleting}
                                type={DeleteConfirmationAlertType.Card}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UserEditModalSkeleton />
      )}
    </>
  );
}

export function UserEditModalSkeleton() {
  return (
    <div className="c-modal__root">
      <div className="c-modal__wrapper">
        <div className="c-modal c-modal--overflow">
          <div className="c-modal__header">
            <div className="c-block c-block--spacing-t-extra-small">
              <div className="o-container-fluid">
                <div className="o-row o-row--fluid c-block__row u-flex-row-reverse">
                  <div className="o-col">
                    <Skeleton width={250} />
                  </div>
                  <div className="o-col u-flex-grow">
                    <p className="c-modal__headline">
                      <Skeleton width={250} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-modal__main">
            <div className="c-block c-block--spacing-t-extra-small c-block--spacing-b-small">
              <div className="o-container-fluid">
                <div className="o-row">
                  <div className="o-col-6@sm o-col-7@md o-col-8@lg">
                    <div className="o-row">
                      <div className="o-col-6@lg o-col-5@xl">
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={250} />
                          </h6>

                          <Skeleton width={340} height={250} />
                        </div>
                      </div>

                      <div className="o-col-6@lg o-col-7@xl">
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={234} height={60} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={234} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={480} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={480} height={60} />
                            </div>
                            <div className="o-col-12">
                              <Skeleton width={480} height={60} />
                            </div>
                          </div>
                        </div>
                        <div className="u-mb-spacer-base-large">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="o-row o-row--small-gutters">
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={250} height={50} />
                            </div>
                            <div className="o-col-6@md o-col-12@lg o-col-6@xl">
                              <Skeleton width={250} height={50} />
                            </div>
                          </div>
                          <p className="c-note">
                            <Skeleton count={2} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-6@sm o-col-5@md o-col-4@lg">
                    <div className="c-card c-card--bg-blue">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6>
                            <Skeleton width={250} />
                          </h6>
                          <div className="c-card__desc">
                            <p>
                              <Skeleton count={2} />
                            </p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <Skeleton width={250} height={50} />
                          </div>
                          <div className="o-col c-button-group__inline">
                            <Skeleton width={250} height={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
