import * as React from "react";
import {
  ColumnDef,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReactComponent as ChevronUp } from "assets/images/icon/chevron-up.svg";
import { ReactComponent as ChevronDown } from "assets/images/icon/chevron-down.svg";
import { ICubeWidgetComponentProps } from "./types";
import Pagination from "components/cards/pagination";
import utils from "./utils";
import { useMemo } from "react";

const TableWidget: React.FunctionComponent<ICubeWidgetComponentProps> = ({
  dataSource,
  resultSet,
  offsetHandler,
  sortingHandler,
}) => {

  const [columns, setColumns] = React.useState<ColumnDef<{ [k: string]: string }, string>[]>([]);
  const [data, setData] = React.useState<{ [k: string]: string }[]>([]);
  const [tableSorting, setTableSorting] = React.useState<SortingState>([]);
  const columnHelper = createColumnHelper<{ [k: string]: string }>();
  const queryLimit = useMemo(() => dataSource.getLimit(), [dataSource]);

  // TODO: reset offset after the query filters have changed
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: tableSorting,
    },
    manualSorting: true,
    manualPagination: true,
    onSortingChange: setTableSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  React.useEffect(() => {
    if (sortingHandler)
      sortingHandler(
        tableSorting && tableSorting.length > 0
          ? tableSorting.map((rule) => [
              utils.table.getFieldForColumnId(rule.id),
              rule.desc ? "desc" : "asc",
            ])
          : []
      );
  }, [tableSorting]);

  React.useEffect(() => {
    if (resultSet) {
      const tableColumns = resultSet.tableColumns(dataSource.pivotConfig);

      table.setOptions({
        ...table.options,
        pageCount: queryLimit? Math.ceil((resultSet.totalRows() ?? -1) / queryLimit) : undefined,
      });

      setColumns(
        tableColumns.map((col, i) => {
          const columnId = utils.table.getFieldColumnId(col.key);
          const formatter =
            utils.formatting.getDataSourceFieldFormatter(dataSource, col.key) ??
            ((val) => val);
          return columnHelper.accessor(columnId, {
            cell: (info) => {
              return formatter(info.getValue() ?? "");
            },
            header: () =>
              (dataSource.labelsConfig ?? {})[col.key]
                ? dataSource.labelsConfig![col.key]
                : col.shortTitle,
            footer: ({ table }) => {
              if (i === 0) {
                return <span>Grand Total</span>;
              }

              return dataSource.subtotalConfig && dataSource.subtotalConfig[col.key] ? (
                <span>
                  {dataSource.subtotalConfig[col.key](table.getFilteredRowModel().rows)}
                </span>
              ) : null;
            },
          });
        })
      );

      setData(
        utils.table.formatTableData(
          tableColumns,
          resultSet.tablePivot(dataSource.pivotConfig)
        )
      );
    }
  }, [resultSet]);

  React.useEffect(() => {
    if (offsetHandler && queryLimit)
      offsetHandler(table.getState().pagination.pageIndex * queryLimit);
  }, [table.getState().pagination.pageIndex]);

  const dimensions = Object.keys(resultSet.annotation().dimensions);
  const dimensionVals = dimensions.reduce((previous, current) => {
    return { ...previous, [current]: [] };
  }, {} as { [dimension: string]: unknown[] });

  return (
    <>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      className="u-flex"
                      {...{
                        style: { cursor: "pointer" },
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      <div className="u-self-center">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      {{
                        asc: (
                          <div className="u-self-center u-ml-spacer u-flex-shrink-0">
                            <ChevronUp width={10} />
                          </div>
                        ),
                        desc: (
                          <div className="u-self-center u-ml-spacer u-flex-shrink-0">
                            <ChevronDown width={10} />
                          </div>
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.forEach((row) => {
            row.getAllCells().forEach((cell) => {
              const field = utils.table.getFieldForColumnId(cell.column.id);
              if (dimensions.includes(field)) {
                dimensionVals[field].push(cell.getValue());
              }
            });
          })}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getAllCells().map((cell) => {
                let rowSpan = 1;
                let rowIndex = cell.row.index;
                const field = utils.table.getFieldForColumnId(cell.column.id);
                const matchesPreviousRowValue =
                  dimensionVals[field] !== undefined &&
                  dimensionVals[field][rowIndex - 1] === cell.getValue();
                if (dimensions.includes(field) && !matchesPreviousRowValue) {
                  while (dimensionVals[field][++rowIndex] === cell.getValue()) {
                    rowSpan++;
                  }
                }

                if (rowSpan > 1) {
                  return (
                    <td key={cell.id} rowSpan={rowSpan} style={{ verticalAlign: "top" }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                }

                if (!matchesPreviousRowValue) {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                }

                return null;
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <Pagination
        currentPage={table.getState().pagination.pageIndex + 1}
        totalPages={table.getPageCount()}
        pageNeighbours={1}
        alwaysShowFirstLast={true}
        alwaysShowPrevNext={true}
        onPageSelect={(page) => {
          table.setPageIndex(page - 1);
        }}
      />
    </>
  );
};

export default TableWidget;
