import * as React from 'react';

// Icons
// import { ReactComponent as IconBell } from 'assets/svg/bell.svg';
// import { ReactComponent as IconClose } from 'assets/svg/close.svg';
// import { ReactComponent as IconEnvelopeOpen } from 'assets/svg/envelope-open.svg';
// import { ReactComponent as IconEnvelopeFill } from 'assets/svg/envelope--fill.svg';

interface INotificationsMenuProps {
}

const NotificationsMenu: React.FunctionComponent<INotificationsMenuProps> = (props) => {
  // return (
  //   <div className="c-site-notifications">
  //     <div className="c-site-notifications__toggle c-popup__pointer">
  //       <div>
  //         <IconBell className="o-svg-icon" />
  //         <span className="c-tag c-tag--small">2</span>
  //       </div>
  //     </div>
  //     <div className="c-popup">
  //       <div className="c-popup__header">
  //         <p className="c-popup__title">Notifications</p>
  //         <div className="c-popup__toggle"><IconClose className="o-svg-icon" /></div>
  //       </div>
  //       <div className="c-popup__body">
  //         <ul className="c-popup__list c-popup__list--small c-popup__notifications">
  //           <li className="c-popup__notifications-item--unread">
  //             <div><IconEnvelopeFill className="o-svg-icon" /><span>Planned maintenance on Tuesday<span>Aug 27, 10:02am</span></span></div>
  //           </li>
  //           <li>
  //             <div><IconEnvelopeOpen className="o-svg-icon" /><span>Lorem Ipsum Dolor<span>Aug 27, 10:02am</span></span></div>
  //           </li>
  //         </ul>
  //         <p className="c-popup__info">You don’t have older notifications</p>
  //       </div>
  //     </div>
  //   </div>
  // );
  return null; // TODO: notification system
};

export default NotificationsMenu;
