import * as React from 'react';

// Icon
import { useContext, useRef, useState } from 'react';
import { PageContext } from './page-context';
import { Link } from 'react-router-dom';
import api from 'api';
import useRequest from 'api/use-request';
import useClickAway from 'hooks/use-click-away';
import classNames from 'classnames';
import PageTitle from './page-title';

interface IGroupsMenuProps {
}

const GroupsMenu: React.FunctionComponent<IGroupsMenuProps> = (props) => {
  const page = useContext(PageContext);
  let user = useRequest(api.users.getCurrentUser());
  let [isOpen, setIsOpen] = useState(false);

  const trigger = useRef<HTMLDivElement>(null);
  useClickAway(trigger, () => setIsOpen(false));

  return (
    <div>
      {user.data && user.data.groups.length > 1 ? (
        <div className={classNames(['c-site-header__group', { 'has-popup-open': isOpen }])} ref={trigger}>
          <div className="c-site-header__logo c-popup__pointer" onClick={() => setIsOpen(!isOpen)}>
            <PageTitle />
          </div>
          <div className="c-popup">
            <div className="c-popup__header c-popup__header--sm">
              <p className="c-popup__title">Select Group</p>
            </div>
            <div className="c-popup__body">
              <ul className="c-page-nav__menu">
                {user.data?.groups.map((group) =>
                  <li key={group.first_dashboard_id} className={classNames([{ "is-selected": group.id === page.selectedGroupId }])}>
                    <Link onClick={() => { setIsOpen(false) }} to={`/dashboard/` + group.first_dashboard_id}><span>{group.name}</span></Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="c-site-header__logo">
          <PageTitle />
        </div>
      )
      }
    </div>
  );
};

export default GroupsMenu;
