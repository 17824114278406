import React from "react";
import { IDimensionFilter } from "../interfaces";
import { Dropdown } from "../dropdown";

const TimeFilter: React.FunctionComponent<IDimensionFilter> = ({
  dimension,
  label,
  icon,
  alignment,
}) => {
  return (
    <Dropdown
      key={`dropdownfilter__${dimension}`}
      dimension={dimension}
      label={label ?? dimension}
      icon={icon}
      alignment={alignment}
      isTimeDimension={true}
    />
  );
};

export default TimeFilter;