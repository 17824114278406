import { FieldArray, FieldArrayConfig } from 'formik';
import * as React from 'react';

export interface IRepeatableFieldProps extends FieldArrayConfig{
}

export default function RepeatableField ({ children, ...props }: IRepeatableFieldProps) {
  return (
    <FieldArray {...props}>
      { children }
    </FieldArray>
  );
}
