import * as React from 'react';

import Pagination from 'components/cards/pagination';
import { listingContext } from 'components/cards/listing-context';
import { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string'
import { SelectBoxControl } from 'components/controls/select-box-control';
import { isArray } from 'lodash';

const ListingFooter: React.FunctionComponent = () => {
  let listing = useContext(listingContext);
  let location = useLocation();
  let navigate = useNavigate();
  let limitOptions = [12, 24, 36, 48].map((value, index) => {
    return { value: value.toString(), label: value.toString() };
  });

  function changeLimit(limit: string) {
    let params = queryString.parse(location.search);
    navigate(location.pathname + '?' + queryString.stringify({ ...params, limit }));
  }

  function currentLimit() {
    let params = queryString.parse(location.search);
    let limit = params.limit ? (isArray(params.limit) ? params.limit[0] : params.limit) : 12;
    if (!limit) limit = 12; // Fix for TS18047: 'limit' is possibly 'null'.
    return limit.toString();
  }

  return (
    <div className="o-row o-row--fluid c-listing__footer">
      <div className="o-col u-justify-start@md">
        <div className="c-actions">
          <div className="c-actions__col">
            {listing.data && (
              <p className="c-caption">{`Showing ${listing.data.meta?.from} to ${listing.data.meta?.to} of ${listing.data.meta?.total} entries`}</p>
            )}
          </div>
        </div>
      </div>
      <div className="o-col-12 o-col@md">
        {listing.data && listing.data.meta.last_page > 1 && (
          <Pagination
            currentPage={listing.data.meta.current_page}
            totalPages={listing.data.meta.last_page}
            pageNeighbours={1}
            alwaysShowFirstLast={true}
            alwaysShowPrevNext={true}
            onPageSelect={(page) => {
              const params = queryString.parse(location.search);
              navigate(location.pathname + '?' + queryString.stringify({ ...params, page }));
            }}
          />
        )}
      </div>
      <div className="o-col u-justify-end@md">
        <div className="c-actions">
          <SelectBoxControl
            title={'Entries per page:'}
            subtitle={'Entries per page:'}
            options={limitOptions}
            currentValue={currentLimit()}
            onChange={(selectedValue) => {
              changeLimit(selectedValue);
            }}
            listClass={"c-dropdown__list--buttons"}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingFooter;
