import { FormatConfig } from "data/types";
import { CubeWidgetDataSource } from "data/utils";

export const getDataSourceFieldFormatter = (
  dataSource: CubeWidgetDataSource,
  field: string,
  area?: keyof Omit<FormatConfig, "any">
) => {
  const cfg: CubeWidgetDataSource["formatConfig"] = dataSource.formatConfig ?? {};

  if (!!cfg[area ?? "any"]) {
    if (cfg[area ?? "any"]![field]) {
      return cfg[area ?? "any"]![field];
    }

    const fields = Object.keys(cfg[area ?? "any"]!);
    const partialMatch = fields.find((f) => field.startsWith(f));
    if (partialMatch) {
      return cfg[area ?? "any"]![partialMatch];
    }
  }

  if (!!cfg.any && cfg.any[field]) {
    return cfg.any[field];
  }

  return null;
};
