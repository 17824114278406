import React, { useEffect } from "react";
import { CubeContextFilter, useCubeDashboardContext } from "../contexts/dashboard-context";
import { IDimensionFilter } from "./interfaces";
import { ReactComponent as IconClose } from "assets/svg/close.svg";

const isSupportedFilterElement = (props: any): props is IDimensionFilter => {
  const requiredProps: (keyof IDimensionFilter)[] = ["dimension"];
  return requiredProps.every((prop) => prop in props);
};

interface IFilterProps {
  children: React.ReactElement | React.ReactElement[] | null | undefined;
}

const Filter: React.FunctionComponent<IFilterProps> = ({ children }) => {
  const { setDefaultFilter, clear, isDirty } = useCubeDashboardContext();

  const elements =
    React.Children.map(
      children,
      (child: React.ReactElement<IDimensionFilter> | null | undefined, index) => {
        const supported =
          child && React.isValidElement(child) && isSupportedFilterElement(child.props);

        if (!supported) {
          return null;
        }

        return React.cloneElement(child, {
          icon: index === 1,
          alignment: index === 0 ? "left" : "right",
        });
      }
    )?.filter((widget) => !!widget) ?? [];

    useEffect(() => {
      const defaults: CubeContextFilter = {};

      React.Children.forEach(
        children,
        (child: React.ReactElement<IDimensionFilter> | null | undefined, index) => {
          const supported =
            child && React.isValidElement(child) && isSupportedFilterElement(child.props);

          if (!supported) {
            return null;
          }

          if (child.props.defaultValue) {
            defaults[child.props.dimension] = child.props.defaultValue;
          }
        }
      )

      setDefaultFilter(defaults);
    }, [children, setDefaultFilter])

  return (
    <div className="u-mt-spacer-base-small- u-mb-spacer-base c-filters-sticky">
      <div className="o-row o-row--fluid u-justify-between u-items-center">
        <div className="o-col">
          <div className="c-actions">{(elements ?? []).length > 0 && elements[0]}</div>
        </div>
        <div className="o-col">
          <div className="c-actions">
            {elements?.slice(1).map((element) => element)}
            {isDirty() && (
              <div onClick={() => clear()} className="c-actions__col c-actions__col--no-divider">
                <div className="c-tag c-tag--error c-tag--stroke c-tag--link">
                  <IconClose className="o-svg-icon" />
                  Clear all
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
