class UsersRefreshSingleton {
  private _needsRefresh: boolean;
  public get needsRefresh(): boolean {
    return this._needsRefresh;
  }
  public set needsRefresh(v: boolean) {
    this._needsRefresh = v;
  }

  public constructor() {
    this._needsRefresh = false;
  }
}

export const usersRefresh = new UsersRefreshSingleton();
