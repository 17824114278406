import { LabelsConfig } from "data/types";
import { query, filteredBy, formatConfig } from "data/sources/total-conversions";

export {
  query,
  filteredBy,
  formatConfig,
};

export const title: string = "Total Purchases";

export const labelsConfig: LabelsConfig = {
  "campaign_report.conversions": "Purchases",
};

