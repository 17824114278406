import * as React from 'react';
import { ErrorMessage as FormikErrorMesssage } from 'formik';

export const ErrorMessage: React.FC<{ name: string }> = ({ name }) => {
  return (
    <FormikErrorMesssage name={name}>
      {(errorMessage) => (
        <ul className="c-form-element--error__list">
          <li>{errorMessage}</li>
        </ul>
      )}
    </FormikErrorMesssage>
  );
};

export default ErrorMessage;
