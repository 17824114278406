import React, { useEffect } from 'react';
import AppRoutes from 'routes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  useEffect(() => {
    window.loader(false);
  }, []);
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
