import React from "react";
import { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { ResultSet } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { endOfMonth, format, isFuture, parseISO } from "date-fns";
import classNames from "classnames";
import { CubeContextFilterValue, useCubeDashboardContext } from "components/cube/contexts/dashboard-context";
import { formatStartCase, formatTextualMonthYear } from "data/formatters";
import { ReactComponent as IconClose } from "assets/svg/close.svg";
import { ReactComponent as IconControls } from "assets/svg/controls.svg";

interface IDropdownProps {
  dimension: string;
  label: string;
  icon?: boolean;
  alignment?: "right" | "left";
  isTimeDimension?: boolean;
}

export const Dropdown: React.FunctionComponent<IDropdownProps> = ({
  dimension,
  label,
  icon,
  alignment,
  isTimeDimension,
}) => {
  const { filter, updateFilter } = useCubeDashboardContext();

  const [isOpen, setIsOpen] = useState(false);
  const [searchOptionsText, setSearchOptionsText] = useState("");
  const [lastResultSet, setLastResultSet] = useState<ResultSet<any> | null>(null);

  const clickOutTrigger = useRef<HTMLDivElement>(null);
  useClickAway(clickOutTrigger, () => setIsOpen(false));

  const isMultiselect = () => !isTimeDimension;

  const searchFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOptionsText(event.target.value.trim());
  };

  const toggleSelectItem = (value: string) => {
    let newSelection: CubeContextFilterValue;

    if (isTimeDimension) {
      const dateFrom = new Date(value);
      const dateTo = endOfMonth(dateFrom);
      updateFilter(dimension, [dateFrom, isFuture(dateTo) ? new Date() : dateTo]);
      return;
    }

    if (isMultiselect()) {
      newSelection = [...((filter[dimension] as string[] | undefined) ?? [])];
      const index = ((filter[dimension] as string[] | undefined) ?? []).indexOf(value);
      if (index > -1) {
        newSelection.splice(index, 1);
      } else {
        newSelection.push(value);
      }
    } else {
      if (((filter[dimension] as string[] | undefined) ?? []).includes(value)) {
        newSelection = [];
      } else {
        newSelection = [value];
      }
    }

    updateFilter(dimension, newSelection);
  };

  const { resultSet, isLoading, error } = useCubeQuery({
    measures: ["campaign_report.const"],
    dimensions: isTimeDimension ? undefined : [dimension],
    timeDimensions: isTimeDimension
      ? [
          {
            dimension: dimension,
            granularity: "month",
          },
        ]
      : undefined,
  });

  useEffect(() => {
    if (resultSet) {
      setLastResultSet(resultSet);
    }
  }, [resultSet]);

  if (isLoading && !lastResultSet) {
    throw new Promise(() => {});
  }

  if (error || !lastResultSet) {
    return <></>;
  }

  const values = lastResultSet
    .categories({
      x: [dimension],
      y: ["measures"],
      fillMissingDates: isTimeDimension,
    })
    .map((categories) => categories.x)
    .reverse();

  const options = values
    .map((value) => ({
      value,
      label: isTimeDimension ? formatTextualMonthYear(value) : formatStartCase(value),
      selected: isTimeDimension
        ? format(parseISO(value), "yyyy-MM-dd") ===
          (filter[dimension] ? format(filter[dimension]![0] as Date, "yyyy-MM-dd") : "")
        : ((filter[dimension] ?? []) as string[]).includes(value),
    }))
    .filter(({ label }) => {
      return searchOptionsText
        ? label.toLowerCase().indexOf(searchOptionsText.toLowerCase()) >= 0
        : true;
    });

  return (
    <div className="c-actions__col" ref={clickOutTrigger}>
      {icon && (
        <div className="c-actions__icon">
          <IconControls className="o-svg-icon" />
        </div>
      )}
      <div
        className={classNames([
          "c-dropdown-wrapper",
          { "has-dropdown-open": isOpen },
          { "has-selected-items": (filter[dimension]?.length ?? 0) > 0 },
        ])}
      >
        <span className="c-dropdown-value" onClick={() => setIsOpen(!isOpen)}>
          {isTimeDimension && filter[dimension] !== undefined ? (
            <>{options.find((option) => option.selected)?.label}</>
          ) : (
            <>{label}</>
          )}
        </span>
        <span className={`c-dropdown c-dropdown--${alignment}`}>
          <span className="c-dropdown__header c-dropdown__header--sm">
            <span className="c-dropdown__title">
              {label} ({options.length}):
            </span>
            <span className="c-dropdown__toggle">
              <IconClose className="o-svg-icon" />
            </span>
          </span>

          {options.length > 0 && isMultiselect() && (
            <span className="c-dropdown__search">
              <div className="c-form-element c-form-element--small c-form-element--style-fill">
                <label htmlFor={`search-${dimension}`} className="c-form-label u-hidden">
                  Search
                </label>
                <div className="c-form-element__field">
                  <input
                    name={`search-${dimension}`}
                    type="text"
                    id={`search-${dimension}`}
                    placeholder="Search"
                    onChange={searchFieldChanged}
                  />
                </div>
              </div>
            </span>
          )}

          {options.length > 0 && (
            <span className="c-dropdown__body" /*onScroll={handleScroll}*/>
              {isMultiselect() &&
                options.map(({ value, label, selected }, index) => (
                  <span className="c-dropdown__form" key={index}>
                    <div className="c-form-element c-form-element--style-line c-form-element--checkbox">
                      <div className="c-form-element__field">
                        <input
                          type="checkbox"
                          id={`${dimension}-${index}`}
                          value={value}
                          onChange={() => toggleSelectItem(value)}
                          checked={selected}
                        />
                        <label htmlFor={`${dimension}-${index}`}>{label}</label>
                      </div>
                    </div>
                  </span>
                ))}
              {!isMultiselect() && (
                <ul className="c-dropdown__list">
                  {options.map(({ value, label, selected }, index) => (
                    <li
                      key={index}
                      className={classNames({
                        "is-selected": selected,
                      })}
                    >
                      <span
                        onClick={() => {
                          toggleSelectItem(value);
                          setIsOpen(false);
                        }}
                      >
                        {label}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};
