import * as widget from './widget';
import * as table from './table';
import * as formatting from './formatting';
import * as colors from './colors';
import * as labels from './labels';
import * as suspense from './suspense';

 const utils ={
  widget,
  table,
  formatting,
  colors,
  labels,
  suspense,
}

export default utils;
