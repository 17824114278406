import React from "react";
import { UseCubeQueryResult, useCubeQuery } from "@cubejs-client/react";
import { PivotConfig, Query, QueryOrder, ResultSet, TimeDimension } from "@cubejs-client/core";
import {
  CubeWidgetDataSourceConfig,
  FormatConfig,
  ICubeDataSourceDefinition,
  LabelsConfig,
  MappableFilterList,
  SubtotalConfig,
  TCubeQuery,
  TSortingRule,
} from "data/types";
import {
  ContextFilterDateRange,
  CubeContextFilter,
  CubeContextFilterValue,
  useCubeDashboardContext,
} from "components/cube/contexts/dashboard-context";
import _ from "lodash";
import { format } from "date-fns";

export const buildCubeWidgetDataSourceRegistry = <
  T extends { [key: string]: ICubeDataSourceDefinition }
>(
  dataSources: T
) => new Registry(dataSources);

/**
 * Returns whether a query is a data blend query
 * @param q
 * @returns
 */
export function isDBQ(q: TCubeQuery): q is Query[] {
  return _.isArray(q);
}

/**
 * Returns whether a value is a valid filter date range
 * @param v
 * @returns
 */
function isValidDateRange(v: CubeContextFilterValue): v is ContextFilterDateRange {
  return v?.length === 2 && v[0] !== null && v[1] !== null && v[1] > v[0];
}

/**
 * React hook to use a cube datasource query with all the filters applied
 * @param datasource
 * @param contextFilter
 * @param offset
 * @param sorting
 * @returns
 */
export function useDataSourceQuery(
  datasource: CubeWidgetDataSource,
  contextFilter?: CubeContextFilter,
  offset?: number,
  sorting?: TSortingRule[]
): UseCubeQueryResult<any> {
  const { isReady } = useCubeDashboardContext();

  if (!isReady()) {
    throw new Promise(() => {});
  }

  const [lastResultSet, setLastResultSet] = React.useState<ResultSet<any> | null>(null);

  const q = datasource.getQuery(contextFilter, offset, sorting);
  const result = useCubeQuery(q);

  React.useEffect(() => {
    if (result.resultSet) {
      setLastResultSet(result.resultSet);
    }
  }, [result.resultSet]);

  return { ...result, resultSet: lastResultSet };
}

/**
 * Datasource class to encapsulate a cube query, widget pivoting, formatting and filtering.
 * @param datasource
 * @param contextFilter
 * @param offset
 * @param sorting
 * @returns
 */
export class CubeWidgetDataSource {
  id: string;
  title: string;
  query: TCubeQuery;
  pivotConfig?: PivotConfig;
  formatConfig?: FormatConfig;
  subtotalConfig?: SubtotalConfig;
  labelsConfig?: LabelsConfig;
  filteredBy?: MappableFilterList;

  constructor(id: string, definition: ICubeDataSourceDefinition) {
    this.id = id;
    this.title = definition.title;
    this.pivotConfig = definition.pivotConfig;
    this.formatConfig = definition.formatConfig;
    this.subtotalConfig = definition.subtotalConfig;
    this.labelsConfig = definition.labelsConfig;
    this.filteredBy = definition.filteredBy;

    this.query = {
      measures: !isDBQ(definition.query)
        ? definition.query.measures?.filter((m) => m !== undefined)
        : (definition.query
            .flatMap((q) => q.measures)
            .filter((m) => m !== undefined) as string[]),
      timeDimensions: !isDBQ(definition.query)
        ? definition.query.timeDimensions?.map((td) => td)
        : definition.query[0].timeDimensions?.map((td) => td),
      limit: !isDBQ(definition.query)
        ? definition.query.limit ?? undefined
        : definition.query[0].limit ?? undefined,
      dimensions: !isDBQ(definition.query)
        ? definition.query.dimensions?.filter((d) => d !== undefined)
        : definition.query[0].dimensions?.filter((d) => d !== undefined),
      filters: !isDBQ(definition.query)
        ? definition.query.filters?.filter((f) => f !== undefined)
        : definition.query[0].filters?.filter((f) => f !== undefined),
      order: !isDBQ(definition.query)
        ? definition.query.order
        : definition.query[0].order,
    }
  }

  /**
   * Get a copy of the query with all the filters applied.
   * @param contextFilter
   * @param offset
   * @param sorting
   * @returns
   */
  public getQuery(
    contextFilter?: CubeContextFilter,
    offset?: number,
    sorting?: TSortingRule[]
  ): TCubeQuery {
    const q = _.cloneDeep<TCubeQuery>(this.query);
    return this.applyContextFilters(q, contextFilter, this.filteredBy, offset, sorting);
  }

  public getTimedimensions(): TimeDimension[] | undefined {
    return isDBQ(this.query)? this.query.flatMap((query) => query.timeDimensions).filter((dimension) => dimension) as TimeDimension[] : this.query.timeDimensions;
  }

  public getMeasures(): string[] | undefined {
    return isDBQ(this.query)? this.query.flatMap((query) => query.measures).filter((measure) => measure) as string[] : this.query.measures;
  }

  public getLimit(): number | undefined {
    if (isDBQ(this.query)) {
      const limits = this.query.flatMap((query) => query.limit).filter((limit) => limit) as number[];
      return limits.sort((a, b) => a - b)[0];
    } else if (this.query.limit) {
      return this.query.limit;
    } else {
      return undefined;
    }
  }

  public update({ pivotConfig, formatConfig, subtotalConfig, labelsConfig, filteredBy }: CubeWidgetDataSourceConfig) {
    this.pivotConfig = pivotConfig ?? this.pivotConfig;
    this.formatConfig = formatConfig ?? this.formatConfig;
    this.subtotalConfig = subtotalConfig ?? this.subtotalConfig;
    this.labelsConfig = labelsConfig ?? this.labelsConfig;
    this.filteredBy = filteredBy ?? this.filteredBy;
  }

  /**
   * Apply all the filters to the query. Works recursively for data blend queries.
   * @param query
   * @param filter
   * @param relevantFilters
   * @param offset
   * @param sorting
   * @returns
   */
  public applyContextFilters(
    query: TCubeQuery,
    filter?: CubeContextFilter,
    relevantFilters?: MappableFilterList,
    offset?: number,
    sorting?: TSortingRule[]
  ): TCubeQuery {
    if (!filter || !relevantFilters || relevantFilters.length === 0) {
      return query;
    }

    // Data blend queries are made up of an array of regular queries.
    if (isDBQ(query)) {
      query.forEach((q) => {
        this.applyContextFilters(q, filter, relevantFilters, offset, sorting);
      });
      return query;
    }

    // check if limit is defined
    if (query.limit) {
      // always calculate total when limit is defined
      query.total = true;
    }

    // check if offset is defined
    if (offset) {
      // apply offset to query
      query.offset = offset;
    }

    // check if there are any sorting rule to apply
    if (sorting && sorting.length > 0) {
      // apply sorting rules
      query.order = sorting.reduce((previous, [field, order]) => {
        return { ...previous, [field]: order };
      }, {} as { [key: string]: QueryOrder });
    }

    Object.entries(filter).forEach(([key, value]) => {
      // bail if we don't have a value or no relevant filters
      if (!value || !relevantFilters) {
        return;
      }

      // check if key is present in either an array or tuple of strings
      if (
        !relevantFilters.some((relevantFilter) =>
          Array.isArray(relevantFilter) ? relevantFilter[0] === key : relevantFilter === key
        )
      ) {
        return;
      }

      // get the mapped key from any tuples in relevantFilters
      let mappedKey: string | undefined = relevantFilters.find(
        (relevantFilter) => Array.isArray(relevantFilter) && relevantFilter[0] === key
      )?.[1];
      mappedKey = mappedKey ? mappedKey : key;

      // check if value is a date range
      if (isValidDateRange(value)) {
        // check if there are any time dimensions to consider
        if (query.timeDimensions) {
          // find first dimentsion match for key
          const dimensionIdx = query.timeDimensions?.findIndex((d) => d.dimension === mappedKey);
          // check if match was found
          if (dimensionIdx > -1) {
            query.timeDimensions[dimensionIdx].dateRange = [
              format(value[0]!, "yyyy-MM-dd"),
              format(value[1]!, "yyyy-MM-dd"),
            ];
          }
        }
        return;
      }

      if (!query.filters) {
        query.filters = [];
      }

      query.filters.push({
        member: mappedKey,
        operator: "equals",
        values: _.isArray(value) ? value : [value],
      });
    });

    return query;
  }

}

class Registry<T extends { [key: string]: ICubeDataSourceDefinition }> {
  private dataSources: Map<keyof T, CubeWidgetDataSource> = new Map();

  constructor(data: T) {
    Object.entries(data).forEach(([id, specification]) => {
      this.dataSources?.set(id, new CubeWidgetDataSource(id, specification));
    });
  }

  get(dataSourceId: keyof T): CubeWidgetDataSource {
    return this.dataSources.get(dataSourceId)!;
  }
}
