import * as z from 'zod';

export const Sort = z.enum([
  'asc',
  'desc'
]);

export interface Paginated<T> {
  links: {
    first: string,
    last: string,
    prev: string,
    next: string
  },
  meta: {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number
  },
  data: T;
}

export const Paginate = function<T extends z.ZodTypeAny>(data: T) {
  return z.lazy(() =>
    z.object({
      links: LinksSchema,
      meta: MetaSchema,
      data: data,
    })
  );
}

export const LinksSchema = z.object({
  first: z.string().url().optional(),
  last: z.string().url().optional(),
  prev: z.string().url().optional(),
  next: z.string().url().optional(),
});

export const MetaSchema = z.object({
  current_page: z.number().int(),
  from: z.number().int().optional(),
  last_page: z.number().int(),
  path: z.string(),
  per_page: z.number().int(),
  to: z.number().int().optional(),
  total: z.number().int(),
});

export const GetListingRequestSchema = z.object({
  page: z.number().int().optional(),
  limit: z.number().int().optional(),
  sort: z.string().optional(),
  direction: Sort.optional(),
});

export type Links = z.TypeOf<typeof LinksSchema>
export type Meta = z.TypeOf<typeof MetaSchema>
export type GetListingRequest = z.TypeOf<typeof GetListingRequestSchema>
