import * as React from 'react';

import GroupGeneralTab from 'components/tabs/group-general';

const AddGroupPage: React.FunctionComponent = () => {

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">New Client</h2>
        </div>
        <GroupGeneralTab isNew={true} groupId={'0'} />
      </div>
    </section>
  );
};

export default AddGroupPage;
