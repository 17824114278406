export function sizedUserProfileImageURL(url: string): string {
  return url + "?" + new URLSearchParams({
    'w': '54',
    'h': '54',
    'fit': 'crop',
    'crop': 'focalpoint',
    'fp-x': '0',
    'fp-y': '0',
  }).toString();
}

export function sizedPreviewUserProfileImageURL(url: string): string {
  return url + "?" + new URLSearchParams({
    'w': '108',
    'h': '108',
    'fit': 'crop',
    'crop': 'focalpoint',
    'fp-x': '0',
    'fp-y': '0',
  }).toString();
}

export function sizedGroupLogoImageURL(url: string): string {
  return url + "?" + new URLSearchParams({
    'w': '300',
    'h': '300',
    'fit': 'max',
  }).toString();
}