import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingSkeleton: React.FunctionComponent = () => {
  return (
    <>
      <div className="u-mt-spacer-base-small- u-mb-spacer-base">
        <div className="o-row o-row--fluid u-justify-between u-items-center">
          <div className="o-col">
            <div className="c-actions">
              <div className="c-actions__col">
                <Skeleton height={25} width={100} />
              </div>
            </div>
          </div>
          <div className="o-col">
            <div className="c-actions">
              <div className="c-actions__col">
                <Skeleton height={25} width={250} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-loading-dashboard">
        <div className="c-site-logo c-site-logo--basic c-site-logo--sm"></div>
        <p className="c-note">Loading dashboard...</p>
      </div>
    </>
  );
};

export default LoadingSkeleton;
