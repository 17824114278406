import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { ReactChild } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface ITabsPanelProps {
  children: ReactChild,
}

export interface TabSettings {
  label: string,
  path: string,
}

export interface ITabUrlParams {
  activeTab: string;
}

export interface ITabsNavProps {
  tabs: TabSettings[],
  children: ReactChild
}

export function TabsNav ({ tabs, children }: ITabsNavProps) {
  let location = useLocation();

  function isActive(index: number) {
    return _.findIndex(tabs, { path: location.pathname }) === index;
  }

  return (
    <div>
      <nav className="c-tabs-nav u-mb-spacer-section">
        <form className="c-tabs-nav-menu c-tabs-nav-menu--dropdown u-hidden@md">
          <div className="c-form-element c-form-element--style-fill c-form-element--select">
            <label htmlFor="form-12-tabs" className="c-form-label">Go to...</label>
            <div className="c-form-element__field">
              <select id="form-12-tabs">
                { tabs.map((tab, index) =>
                  <option key={index} value={index}>{tab.label}</option>
                )}
              </select>
            </div>
          </div>
        </form>
        <ul className="c-tabs-nav-menu c-tabs-nav-menu--horizontal u-hidden u-flex@md">
          { tabs.map((tab, index) =>
            <li key={index} className={classNames([{'is-selected': isActive(index)}])}>
              <Link to={'../' + tab.path} relative='path'><span>{tab.label}</span></Link>
            </li>
          )}
        </ul>
      </nav>
      <div className="c-tabs-content">
        {children}
      </div>
    </div>
  );
}

export function TabsPanel ({ children }: ITabsPanelProps) {
  return (
    <div className={classNames(['c-tabs-item', 'is-active'])}>
      {children}
    </div>
  )
}
