import * as React from 'react';

import { useContext, useEffect } from 'react';
import { PageContext } from 'components/layout/page-context';
import { UserDetails } from 'components/cards/user-edit';

export interface IProfilePageProps {
  returnTo: string;
}

export default function ProfilePage({ returnTo }: IProfilePageProps) {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle('Edit Profile');
    page.setBackLink(returnTo);
    page.setShowsGroupSwitcher(false);
  });

  return (
    <section className="c-block c-block--spacing-b-small">
      <UserDetails
        isNew={false}
        nextLocation={''}
        onUpdate={() => { }}
        isProfile={true}
      />
    </section>
  );
}
