import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequest from "api/use-request";
import api from "api";
import { DashboardType } from "api/endpoints/dashboards";
import { PageContext } from "components/layout/page-context";
import CubeDashboardPage from "./cube-dashboard";

export interface IDashboardPageProps {}

const DashboardPage = function (props: IDashboardPageProps) {
  let page = useContext(PageContext);
  let [dashboardType, setDastboardType] = useState<DashboardType | undefined>();
  let navigate = useNavigate();

  let { id } = useParams() as {
    id: string;
  };

  const dashboardEntry = useRequest(
    api.dashboards.getDashboard({
      id: id,
    })
  );

  if (dashboardEntry.error) {
    navigate("/404");
  }

  useEffect(() => {
    if (dashboardEntry.data !== undefined) {
      if (dashboardEntry.data?.type !== undefined) {
        setDastboardType(dashboardEntry.data?.type);
      } else {
        navigate("/404");
      }
    }
  }, [dashboardEntry.data, dashboardEntry.error]);

  useEffect(() => {
    if (dashboardEntry.data) {
      page.setTitle(dashboardEntry.data?.group_name);
      page.setSelectedGroupId(dashboardEntry.data?.group_id);
      page.setShowsGroupSwitcher(true);
      if (dashboardEntry.data?.group_logo_url) {
        page.setLogoURL(dashboardEntry.data?.group_logo_url);
      }
      page.setBackLink("");
    } else {
      page.setTitle(" ");
    }
    return () => {
      page.setLogoURL(undefined);
    };
  }, [dashboardEntry, page]);

  return <DashboardComponent dashboardType={dashboardType} client={dashboardEntry.data?.source} />;
};

export default DashboardPage;

export interface IDashboardComponentProps {
  dashboardType: DashboardType | undefined;
  client?: string;
}

const DashboardComponent = function ({ dashboardType, client }: IDashboardComponentProps) {
  switch (dashboardType) {
    case DashboardType.adreport:
      return <CubeDashboardPage client={client} />;
    case DashboardType.url:
      return <div>Generic Iframe dashboard</div>;
    default:
      return <div></div>;
  }
};
