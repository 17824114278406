import { PivotConfig } from "@cubejs-client/core";
import { formatAbbreviatedNumber, formatCurrencyUSD, formatStartCase } from "data/formatters";
import { FormatConfig, LabelsConfig, TCubeQuery } from "data/types";

export const title: string = "Conversions vs. CPA";

export const query: TCubeQuery = {
  measures: [
    "campaign_report.conversions", // bubble.x
    "campaign_report.cpa", // bubble.y
    "campaign_report.clicks", // bubble.r
  ],
  timeDimensions: [
    {
      dimension: "campaign_report.date",
    },
  ],
  dimensions: ["campaign_report.platform"],
  filters: [
    {
      "member": "campaign_report.conversions",
      "operator": "set"
    },
    {
      "member": "campaign_report.cpa",
      "operator": "set"
    }
  ],
};

export const pivotConfig: PivotConfig = {
  x: ["campaign_report.platform"],
  y: ["measures"],
};

export const formatConfig: FormatConfig = {
  any: {
    "campaign_report.cpa": (value) => formatCurrencyUSD(value),
    "campaign_report.platform": (value) => formatStartCase(value),
  },
  tooltip: {
    "campaign_report.conversions": (value) => formatAbbreviatedNumber(value, 0),
    "campaign_report.clicks": (value) => formatAbbreviatedNumber(value, 0),
  },
};

export const labelsConfig: LabelsConfig = {
}

export const filteredBy = ["campaign_report.platform", "campaign_report.date"];
