import React, { ReactChild } from "react";
import { isObject } from "utils/checks";

interface IMainLayoutProps {
  children: ReactChild | INamedChildrenSlots
}

interface INamedChildrenSlots {
  content: ReactChild
}

export default function MainLayout (props: IMainLayoutProps) {
  const { children } = props;
  if (!children) {
    throw new Error("Layouts need view components as children.");
  }

  // Multiple named slots used
  if (hasNamedSlots(children)) {
    const { content } = children;

    return (
      <div>
        {content ? content : null}
      </div>
    );
  }

  // Single component
  return (
    <div className="o-row o-row--no-gutters">
      <div className="o-col-12">
        <div className="c-block c-block--full-height c-block--spacing-t c-block--spacing-b">
          <div className="o-container-fluid o-container--xs u-justify-between">
            <div className="">
              <div className="c-site-logo c-site-logo--basic c-site-logo--sm u-mb-spacer-base"></div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );

};

const hasNamedSlots = (children: any): children is INamedChildrenSlots => isObject(children) && 'content' in children;
