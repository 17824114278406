import config from 'config';
import * as React from 'react';
import { createContext, ReactElement, RefObject, useState } from 'react';

export type IPageContext = {
  title: string;
  setTitle: (title?: string) => void;
  logoURL?: string;
  setLogoURL: (logoURL: string | undefined) => void;
  hasLoaded: boolean;
  setHasLoaded: (newValue: boolean) => void;
  isLoading: boolean;
  setIsLoading: (newValue: boolean) => void;
  backLink?: string;
  setBackLink: (link?: string) => void;
  pageSkeleton?: ReactElement;
  setPageSkeleton: (skeleton: ReactElement) => void;
  selectedGroupId?: string;
  setSelectedGroupId: (selectedGroupId?: string) => void;
  showsGroupSwitcher: boolean;
  setShowsGroupSwitcher: (showsGroupSwitcher: boolean) => void;
  headerRef: RefObject<HTMLElement>;
  footerRef: RefObject<HTMLElement>;
}

export const PageContext = createContext({} as IPageContext);

export type IPageContextProvider = {
  children: ReactElement;
  headerRef: RefObject<HTMLElement>;
  footerRef: RefObject<HTMLElement>;
}

export const PageContextProvider = ({ children, headerRef, footerRef }: IPageContextProvider) => {
  let [title, _setTitle] = useState<string>(config.APP_NAME);
  let [logoURL, setLogoURL] = useState<string>();
  let [backLink, setBackLink] = useState<string>();
  let [hasLoaded, setHasLoaded] = useState<boolean>(false);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [pageSkeleton, setPageSkeleton] = useState<ReactElement>();
  let [selectedGroupId, setSelectedGroupId] = useState<string>();
  let [showsGroupSwitcher, setShowsGroupSwitcher] = useState<boolean>(false);



  function setTitle(title?: string) {
    if (title) {
      _setTitle(title);
      document.title = `${title} - ${config.APP_NAME}`;
    } else {
      _setTitle(config.APP_NAME);
      document.title = config.APP_NAME;
    }
  }

  const initialContext = {
    title,
    setTitle,
    logoURL,
    setLogoURL,
    backLink,
    setBackLink,
    isLoading,
    setIsLoading,
    hasLoaded,
    setHasLoaded,
    pageSkeleton,
    setPageSkeleton,
    selectedGroupId,
    setSelectedGroupId,
    showsGroupSwitcher,
    setShowsGroupSwitcher,
    headerRef,
    footerRef,
  }

  return (
    <PageContext.Provider value={initialContext}>
      {children}
    </PageContext.Provider>
  );
};
