import { useEffect, useMemo, useState } from "react"
import { useCubeDashboardContext } from "components/cube/contexts/dashboard-context";
import utils from "../utils";

export function useColors(labels: string[], shareColors?: boolean) {
  const { getColor } = useCubeDashboardContext();
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const arrayEquales = (arr1: string[], arr2: string[]) => {
      return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
    }
    const updatedColors = labels.map((label, index) => shareColors? getColor(label) : utils.colors.get(index));

    if (!arrayEquales(updatedColors, colors)) {
      setColors(updatedColors);
    }

  }, [labels, getColor, colors]);

  return colors;
}
