import classNames from "classnames";
import * as React from "react";

interface IWidgetContainerProps {
  id: string;
  type: "indicator" | "chart";
  title: string;
  description?: string;
  height?: number | string;
  isLoading?: boolean;
  tabs?: [id: string, label: string][];
  currentTab?: string;
  onTabChange?: (id: string) => void;
  children: React.ReactNode;
}

const WidgetContainer: React.FunctionComponent<IWidgetContainerProps> = ({
  id,
  type,
  title,
  description,
  height,
  isLoading,
  tabs,
  currentTab,
  onTabChange,
  children,
}: IWidgetContainerProps) => {
  const [tabNavItems, setTabItems] = React.useState<[id: string, label: string][] | undefined>(
    tabs
  );

  React.useEffect(() => {
    setTabItems(tabs);
  }, [tabs]);

  const [tabCurrentId, setTabCurrentId] = React.useState<string | undefined>(currentTab);

  React.useEffect(() => {
    setTabCurrentId(currentTab);
  }, [currentTab]);

  return (
    <div
      className={classNames([
        "c-card",
        type,
        {
          "c-card--bg-white": type !== "indicator",
          "c-card--bg-blue": type === "indicator",
        },
      ])}
      id={`widget-container-${id}`}
    >
      {!!isLoading && (
        <div className="c-card__load c-card__load--indeterminate">
          <span className="c-card__load-bar"></span>
        </div>
      )}
      <div
        className={classNames([
          "c-card__body",
          {
            "u-px-0": type === "indicator",
            "u-py-0": type === "indicator",
          },
        ])}
      >
        {type !== "indicator" && (
          <>
            <h3 className="c-card__title u-text-truncate">{title}</h3>
            {description && <p className="c-card__desc">{description}</p>}
          </>
        )}
        {tabNavItems ? (
          <>
            <nav className="c-tabs-nav u-mb-0">
              <form className="c-tabs-nav-menu c-tabs-nav-menu--dropdown u-hidden@md">
                <div className="c-form-element c-form-element--style-fill c-form-element--select">
                  <label htmlFor="form-12-tabs" className="c-form-label">
                    Select...
                  </label>
                  <div className="c-form-element__field">
                    <select
                      id="form-12-tabs"
                      value={tabCurrentId}
                      onChange={(e) => onTabChange && onTabChange(e.currentTarget.value)}
                    >
                      {tabNavItems &&
                        tabNavItems.map(([id, label]) => (
                          <option key={id} value={id}>
                            {label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </form>
              <ul className="c-tabs-nav-menu c-tabs-nav-menu--horizontal u-hidden u-flex@md">
                {tabNavItems &&
                  tabNavItems.map(([id, label]) => (
                    <li key={id} className={tabCurrentId === id ? "is-selected" : ""}>
                      <a
                        href={`#${id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          onTabChange && onTabChange(id);
                        }}
                      >
                        <span>{label}</span>
                      </a>
                    </li>
                  ))}
              </ul>
            </nav>
            <div className="c-tabs-content" style={height ? { height: height } : {}}>
              {children}
            </div>
          </>
        ) : (
          <div style={height ? { height: height } : {}}>{children}</div>
        )}
      </div>
    </div>
  );
};

export default WidgetContainer;
