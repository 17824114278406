import * as React from 'react';

interface IFileUploadIndicatorProps {
  fileName: string | undefined,
}

const FileUploadIndicator: React.FunctionComponent<IFileUploadIndicatorProps> = (props) => {
  return (
    <div className="c-card c-card--link c-card--bg-white is-highlighted">
      <div className="c-card__body">
        <div className="c-card__header">
          <p><strong>Uploading...</strong></p>
          {props.fileName &&
            <p className="c-note">{props.fileName}</p>
          }
          <div className="c-card__load c-card__load--indeterminate"><span className="c-card__load-bar"></span></div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadIndicator;
