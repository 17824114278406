import HttpClient from "api/http-client";
import * as z from 'zod';

export const CubeDashboardTokenResponseSchema = z.object({
  token: z.string().min(1),
});

export const CubeDashboardTokenRequestSchema = z.object({
  dashboard_id: z.string(),
  group_id: z.string().optional(),
});

export type CubeDashboardTokenRequest = z.TypeOf<typeof CubeDashboardTokenRequestSchema>;
export type CubeDashboardTokenResponse = z.TypeOf<typeof CubeDashboardTokenResponseSchema>;

export function getDashboardToken(data: CubeDashboardTokenRequest) {
  return new HttpClient().request<CubeDashboardTokenResponse>({
    method: 'post',
    url: '/cube/dashboardToken',
    validator: CubeDashboardTokenResponseSchema,
    data,
  });
}
