import * as React from 'react';

import UsersListing from 'components/cards/users-listing';

// Icons
import { ReactComponent as IconAdd } from 'assets/svg/add.svg';
import { useContext, useEffect } from 'react';
import { PageContext } from 'components/layout/page-context';
import { Link } from 'react-router-dom';

const UsersIndexPage: React.FunctionComponent = () => {
  const page = useContext(PageContext);

  useEffect(() => {
    page.setTitle('Manage Users');
    page.setBackLink();
    page.setShowsGroupSwitcher(false);
  });

  return (
    <section className="c-block c-block--spacing-b-small">
      <div className="o-container-fluid o-container--full">
        <div className="c-page-title">
          <h2 className="c-title--large">All users</h2>
          <Link to="/users/new" className="c-button c-button--stroke c-button--primary c-button--icon u-hidden@md"><IconAdd className="o-svg-icon" /></Link>
          <Link to="/users/new" className="c-button c-button--stroke c-button--primary u-hidden u-block@md"><IconAdd className="o-svg-icon" /><span>Add new user</span></Link>
        </div>
        <UsersListing showAllUsers={true} groupId={'-1'} />
      </div>
    </section>
  );
};

export default UsersIndexPage;
