import * as React from "react";
import { Formik, Form as FormikForm, FormikConfig, FormikErrors, useFormikContext } from "formik";
// import * as yup from 'yup';
import * as z from "zod";
import { toFormikSchema } from "utils/formik-zod";
import { createContext, useContext } from "react";
import { isFunction } from "lodash";

import Notification from "components/notification";

export enum FormStatus {
  Loading,
  Loaded,
  Submitting,
  Submitted,
  Failed,
  Successful,
}

const FormNotificationContext = createContext<{
  errors?: string[];
  showErrorMessage?: boolean;
  successMessage?: React.ReactNode;
}>({});

type RequiredValues<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

type InferValuesFromSchema<T extends z.ZodTypeAny> = RequiredValues<z.TypeOf<T>>;

export interface FormProps<
  ValidationSchema extends z.ZodTypeAny,
  Values = InferValuesFromSchema<ValidationSchema>
  > extends FormikConfig<Values> {
  validationSchema: ValidationSchema;
  initialValues: Values;
  errors?: FormikErrors<Values> & { __other: string[] };
  showErrorNotificationMessage?: boolean;
  successMessage?: React.ReactNode;
}

export const FormNotification = () => {
  const { submitCount, isValid, status } = useFormikContext();
  const { errors, showErrorMessage, successMessage } = useContext(FormNotificationContext);

  if (successMessage && status === FormStatus.Successful) {
    return (
      <Notification type="success" scrollTo>
        {successMessage}
      </Notification>
    );
  }

  // Show only the message if the form is invalid after submission
  if (!errors && showErrorMessage && submitCount && !isValid) {
    return (
      <Notification type="error" scrollTo>
        <p>Please make sure all required fields are filled out and then try again.</p>
      </Notification>
    );
  }

  if (!errors || !errors.length) {
    return null;
  }

  return (
    <Notification type="error" scrollTo>
      <p>Please make sure all required fields are filled out and then try again.</p>
      <ul className="u-my-1">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </Notification>
  );
};

export const Form = <T extends z.ZodTypeAny>({
  children,
  showErrorNotificationMessage,
  successMessage,
  validationSchema,
  onSubmit,
  ...formikProps
}: FormProps<T>) => {
  // function extendChildren(elements: React.ReactNode, props: any) {
  //   if (elements && React.isValidElement(elements)){
  //     const extendedChildren = React.Children.map(elements, (child: React.ReactElement) => {
  //       if (React.isValidElement(child)) {
  //         return React.cloneElement(child, props);
  //       } else {
  //         return child;
  //       }
  //     });
  //     return elements;
  //   }
  //   return elements;
  // }

  const [errors, setErrors] = React.useState([] as string[]);

  const submitHandler: FormProps<T>["onSubmit"] = (values, helpers) => {
    const submitRes = onSubmit(values, helpers);

    if (submitRes) {
      let newErrors: string[] = [];
      setErrors(newErrors);
      submitRes
        .catch((error) => {
          if (error.response.data.errors[0]) {
            newErrors.push(error.response.data.errors[0]);
          } else if (error.response.data.message) {
            newErrors.push(error.response.data.message);
          }
          setErrors(newErrors);

          console.log(error);
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    }
  };

  return (
    <Formik
      {...{
        ...formikProps,
        validationSchema: toFormikSchema(validationSchema),
        onSubmit: submitHandler,
      }}
    >
      {(formikBag) => (
        <FormikForm>
          <FormNotificationContext.Provider
            value={{
              errors,
              showErrorMessage: showErrorNotificationMessage,
              successMessage,
            }}
          >
            {children
              ? isFunction(children)
                ? (children as (props: typeof formikBag) => React.ReactNode)(formikBag)
                : children
              : null}
          </FormNotificationContext.Provider>
        </FormikForm>
      )}
    </Formik>
  );
};
