import { buildCubeWidgetDataSourceRegistry } from "./utils";
import * as BudgetVsCost from "./sources/budget-cost";
import * as ConversionsVsCPA from "./sources/conversions-cpa";
import * as PlatformImpressions from "./sources/platform-impressions";
import * as PlatformClicks from "./sources/platform-clicks";
import * as PlatformConversions from "./sources/platform-conversions";
import * as PlatformPurchases from "./sources/platform-purchases";
import * as PlatformCost from "./sources/platform-cost";
import * as PlatformPerformanceBreakdown from "./sources/platform-performance-breakdown";
import * as CampaignPerformanceBreakdown from "./sources/campaign-performance-breakdown";
import * as PlatformPerformanceBreakdownZildjian from "./sources/platform-performance-breakdown-zildjian";
import * as CampaignPerformanceBreakdownZildjian from "./sources/campaign-performance-breakdown-zildjian";
import * as MetricTrend from "./sources/metric-trend";
import * as MetricTrendZildjian from "./sources/metric-trend-zildjian";
import * as TotalConversions from "./sources/total-conversions";
import * as TotalPurchases from "./sources/total-purchases";
import * as TotalCost from "./sources/total-cost";
import * as TotalCPA from "./sources/total-cpa";
import * as TotalCVR from "./sources/total-cvr";
import * as TotalRevenue from "./sources/total-revenue";
import * as TotalROAS from "./sources/total-roas";

export const CubeDataSourceRegistry = buildCubeWidgetDataSourceRegistry({
  BUDGET_VS_COST: BudgetVsCost,
  CONVERSIONS_VS_CPA: ConversionsVsCPA,
  PLATFORM_IMPRESSIONS: PlatformImpressions,
  PLATFORM_CLICKS: PlatformClicks,
  PLATFORM_CONVERSIONS: PlatformConversions,
  PLATFORM_PURCHASES: PlatformPurchases,
  PLATFORM_COST: PlatformCost,
  PLATFORM_PERFORMANCE_BREAKDOWN: PlatformPerformanceBreakdown,
  PLATFORM_PERFORMANCE_BREAKDOWN_ZILDJIAN: PlatformPerformanceBreakdownZildjian,
  CAMPAIGN_PERFORMANCE_BREAKDOWN: CampaignPerformanceBreakdown,
  CAMPAIGN_PERFORMANCE_BREAKDOWN_ZILDJIAN: CampaignPerformanceBreakdownZildjian,
  METRIC_TREND: MetricTrend,
  METRIC_TREND_ZILDJIAN: MetricTrendZildjian,
  TOTAL_CONVERSIONS: TotalConversions,
  TOTAL_PURCHASES: TotalPurchases,
  TOTAL_COST: TotalCost,
  TOTAL_CPA: TotalCPA,
  TOTAL_CVR: TotalCVR,
  TOTAL_REVENUE: TotalRevenue,
  TOTAL_ROAS: TotalROAS,
  // ... register any new cube data sources here ...
});
