import type { CubeWidgetDataSourceConfig, TSortingRule } from 'data/types';
import React, { useState, useEffect } from 'react';
import { useCubeDashboardContext } from 'components/cube/contexts/dashboard-context';
import utils from 'components/cube/utils';
import WidgetContainer from 'components/cube/elements/widget-container';
import BarChartWidget from 'components/cube/bar-chart-widget';
import BubbleChartWidget from 'components/cube/bubble-chart-widget';
import LineChartWidget from 'components/cube/line-chart-widget';
import PieChartWidget from 'components/cube/pie-chart-widget';
import NumberWidget from 'components/cube/number-widget';
import TableWidget from 'components/cube/table-widget';
import { CubeWidgetDataSource, useDataSourceQuery } from 'data/utils';

export enum WidgetType {
  BarChart = 'barchart',
  BubbleChart = 'bubblechart',
  LineChart = 'linechart',
  PieChart = 'piechart',
  Indicator = 'indicator',
  Table = 'table',
}
export type IWidget = {
  type: WidgetType;
  title?: string;
  tabLabels?: string[];
  height?: number | string;
  dataSource: CubeWidgetDataSource | CubeWidgetDataSource[];
  sharedColors?: boolean;
} & CubeWidgetDataSourceConfig;

export const Widget: React.FunctionComponent<IWidget> = ({
  type,
  title,
  height,
  dataSource,
  pivotConfig,
  formatConfig,
  subtotalConfig,
  labelsConfig,
  filteredBy,
  tabLabels,
  sharedColors,
}: IWidget) => {
  const [activeDataSource, setActiveDataSource] = useState<CubeWidgetDataSource>(
    Array.isArray(dataSource) ? dataSource[0] : dataSource,
  );

  activeDataSource.update({title, pivotConfig, formatConfig, subtotalConfig, labelsConfig, filteredBy});

  const { filter } = useCubeDashboardContext();
  const [offset, setOffset] = useState<number>();
  const [sorting, setSorting] = useState<TSortingRule[]>();
  const { resultSet, isLoading, error } = useDataSourceQuery(activeDataSource, filter, offset, sorting);

  utils.suspense.handle(resultSet, isLoading);

  if (!resultSet || error) {
    return null;
  }

  const offsetHandler = (offset: number) => {
    setOffset(offset);
  };

  const sortingHandler = (sorting: TSortingRule[]) => {
    setSorting(sorting);
  };

  // Define component based on widget type
  var component;
  switch (type) {
    case WidgetType.BarChart:
      component = <BarChartWidget
        title={title ?? activeDataSource.title}
        height={height}
        dataSource={activeDataSource}
        resultSet={resultSet}
        sharedColors={sharedColors}
      />;
      break;
    case WidgetType.BubbleChart:
      component = <BubbleChartWidget
        title={title ?? activeDataSource.title}
        height={height}
        dataSource={activeDataSource}
        resultSet={resultSet}
        sharedColors={sharedColors}
      />;
      break;
    case WidgetType.LineChart:
      component = <LineChartWidget
        title={title ?? activeDataSource.title}
        height={height}
        dataSource={activeDataSource}
        resultSet={resultSet}
        sharedColors={sharedColors}
      />;
      break;
    case WidgetType.PieChart:
      component = <PieChartWidget
        title={title ?? activeDataSource.title}
        height={height}
        dataSource={activeDataSource}
        resultSet={resultSet}
        sharedColors={sharedColors}
      />;
      break;
    case WidgetType.Indicator:
      component = <NumberWidget
        title={title ?? activeDataSource.title}
        dataSource={activeDataSource}
        resultSet={resultSet}
      />;
      break;
    case WidgetType.Table:
      component = <TableWidget
        title={title ?? activeDataSource.title}
        dataSource={activeDataSource}
        resultSet={resultSet}
        offsetHandler={offsetHandler}
        sortingHandler={sortingHandler}
      />;
      break;
    default:
      component = <div>Wrong widget type</div>;
      break;
  }

  return (
    <WidgetContainer
      id={activeDataSource.id}
      type={type === WidgetType.Indicator ? 'indicator' : 'chart'}
      title={title ?? activeDataSource.title}
      height={height}
      isLoading={isLoading}
      tabs={utils.widget.getDataSourceAsTabs(dataSource, tabLabels)}
      currentTab={activeDataSource.id}
      onTabChange={(id) => {
        if (Array.isArray(dataSource)) {
          setActiveDataSource(dataSource.find((ds) => ds.id === id)!);
        }
      }}
    >
      {component}
    </WidgetContainer>
  );
};

export default Widget;
