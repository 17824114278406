import * as React from "react";

import { ReactComponent as IconAdd } from "assets/svg/add.svg";
import { ReactComponent as IconAddItem } from "assets/svg/add-item.svg";
import { ReactComponent as IconTrash } from "assets/svg/trash.svg";
import { ReactComponent as IconMove } from "assets/svg/move.svg";
import { ReactComponent as IconDropdown } from "assets/svg/dropdown.svg";

import {
  DashboardObjectRequest,
  DashboardObjectRequestSchema,
  DashboardType,
} from "api/endpoints/dashboards";
import { useEffect, useState } from "react";
import classNames from "classnames";
import InputField, { InputFieldType } from "components/form/input";
import { Form, FormNotification, FormStatus } from "components/form/form";
import SelectField from "components/form/select-field";
import api from "api";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import * as z from "zod";
import RepeatableField from "components/form/repeatable-field";
import { ButtonLink, ButtonScope } from "components/button";
import { SubmitButton } from "components/form/submit-button";
import { FieldArrayRenderProps } from "formik";
import _ from "lodash";
import { FormFieldVariant } from "components/form/common";

// TODO: Replace this library with something else for React 18 support: https://github.com/clauderic/react-sortable-hoc/issues/870
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import Skeleton from "react-loading-skeleton";
import DeleteConfirmationAlert, {
  DeleteConfirmationAlertType,
} from "components/form/delete-confirmation";
import { AdReportingClient } from "components/cube/dashboards/client-ad-reporting-dashboard";

export interface IDashboardsTabProps {
  groupId: string;
}

function newDashboardObj(index: number = 0): DashboardObjectRequest {
  return _.clone({
    name: "",
    type: DashboardType.url,
    source: "",
    order: index,
  });
}

export default function DashboardsTab({ groupId }: IDashboardsTabProps) {
  const location = useLocation();
  const searchArgs = queryString.parse(location.search, { parseNumbers: true });
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    dashboards: [] as DashboardObjectRequest[],
  });
  const [deleteable, setDeleatable] = useState({ dashboards: [] as DashboardObjectRequest[] });

  const initForm = async () => {
    const dashboards = await api.dashboards
      .getDashboards({ ...searchArgs, group_id: groupId })
      .fetch();
    if (dashboards.data) {
      const requestObjects = dashboards.data.map((dasboard, index): DashboardObjectRequest => {
        return _.omit(dasboard, ["created_at", "updated_at"]);
      });
      setInitialValues({ dashboards: requestObjects });
    }
    setIsLoaded(true);
  };

  useEffect(() => {
    initForm();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Form
          validationSchema={z.object({
            dashboards: z.array(DashboardObjectRequestSchema),
          })}
          initialValues={initialValues}
          successMessage="Dashboards saved succesfully!"
          onSubmit={(values, { setSubmitting, setStatus }) => {
            let updates: Promise<unknown>[] = [];
            values.dashboards.forEach(({ id, source, type, name, order }, index) => {
              const payload: DashboardObjectRequest = {
                group_id: groupId,
                id,
                source,
                type,
                name,
                order: index,
              };
              if (payload.id) {
                updates.push(api.dashboards.updateDashboard(payload).fetch());
              } else {
                updates.push(api.dashboards.newDashboard(payload).fetch());
              }
            });
            deleteable.dashboards.forEach(({ id }, index) => {
              console.log(`deleting dashboard ${id} at ${index}`);
              if (id) {
                updates.push(
                  api.dashboards
                    .deleteDashboard({ id })
                    .fetch()
                    .then(() => {
                      setDeleatable({ dashboards: deleteable.dashboards.splice(index, 1) });
                    })
                );
              }
            });

            return Promise.all(updates)
              .then(
                (response) => {
                  return initForm();
                },
                (reason) => {
                  setStatus(FormStatus.Failed);
                }
              )
              .finally(() => {
                setStatus(FormStatus.Successful);
              });
          }}
          enableReinitialize
        >
          {({ values, errors }) => (
            <RepeatableField
              name="dashboards"
              render={(helpers) => (
                <div className="o-row">
                  <div className="o-col-8@md">
                    <div className="u-mb-spacer-base-large">
                      <h6>Dashboards</h6>
                      <FormNotification />
                      {values.dashboards.length < 1 ? (
                        <NoDashboardsPlaceholder helpers={helpers} />
                      ) : null}
                      <SortableList
                        lockAxis={"y"}
                        helperClass={"is-selected"}
                        useDragHandle
                        onSortEnd={({ oldIndex, newIndex }) => {
                          helpers.move(oldIndex, newIndex);
                        }}
                      >
                        {values.dashboards.map((dashboard, index) => (
                          <SortableDashboardDetails
                            key={`dashboard-${dashboard.id ? dashboard.id : 0}`}
                            index={index}
                            sortIndex={index}
                            deleteHandler={() => {
                              if (dashboard.id) {
                                setDeleatable({
                                  dashboards: deleteable.dashboards.concat([dashboard]),
                                });
                              }
                              helpers.remove(index);
                            }}
                            {...dashboard}
                          />
                        ))}
                      </SortableList>
                      <div className="u-mb-spacer-base">
                        <ButtonLink
                          outline
                          onClick={() => helpers.push(newDashboardObj(values.dashboards.length))}
                        >
                          <IconAdd className="o-svg-icon" />
                          <span>Add new item</span>
                        </ButtonLink>
                      </div>
                    </div>
                  </div>
                  <div className="o-col-4@md">
                    <div className="c-card c-card--bg-blue">
                      <div className="c-card__body">
                        <div className="c-card__header">
                          <h6>Save</h6>
                          <div className="c-card__desc">
                            <p>
                              These dashboards will be available in the dashboard menu for the
                              client. There are currently {initialValues.dashboards.length}{" "}
                              dashboards saved for this client.
                            </p>
                          </div>
                        </div>
                        <div className="o-row o-row--fluid c-button-group">
                          <div className="o-col">
                            <SubmitButton>
                              <span>Update dashboards</span>
                            </SubmitButton>
                          </div>
                          <div className="o-col c-button-group__inline">
                            <ButtonLink
                              scope={ButtonScope.secondary}
                              onClick={() => {
                                const dashboards = values.dashboards;
                                dashboards.forEach((dashboard, index) => {
                                  console.log(`deleting dashboard ${dashboard.id} at ${index}`);
                                  setDeleatable({
                                    dashboards: deleteable.dashboards.concat([dashboard]),
                                  });
                                  helpers.remove(index);
                                });
                              }}
                            >
                              <IconTrash className="o-svg-icon" />
                              <span>Delete all dashboards</span>
                            </ButtonLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          )}
        </Form>
      ) : (
        <DashboardsTabSkeleton />
      )}
    </>
  );
}

export interface iDashboardDetailsProps
  extends Omit<DashboardObjectRequest, "created_at" | "updated_at"> {
  deleteHandler: () => void;
  isSelected?: boolean;
  sortIndex: number;
}

function DashboardDetails({
  id,
  name,
  type,
  order,
  source,
  isSelected,
  deleteHandler,
  sortIndex,
}: iDashboardDetailsProps) {
  let [isOpen, setIsOpen] = useState(false || name === "");
  let [isDeleting, setIsDeleting] = useState(false);

  const adReportingClients = Object.values(AdReportingClient);

  function dashboardLink() {
    switch (type) {
      case DashboardType.adreport:
        return (
          <Link to={`/dashboard/${id}`} className="c-link-cta-light">
            <span>View</span>
            <IconDropdown className="o-svg-icon o-svg-right o-svg-small" />
          </Link>
        );

      case DashboardType.url:
        return (
          <Link to={source} className="c-link-cta-light">
            <span>View</span>
            <IconDropdown className="o-svg-icon o-svg-right o-svg-small" />
          </Link>
        );

      default:
        return null;
    }
  }

  function sourceLabel(): { label: string; placeholder: string } {
    switch (type) {
      case DashboardType.adreport:
        return {
          label: "Database",
          placeholder: "e.g. client_name",
        };

      case DashboardType.url:
        return {
          label: "URL",
          placeholder: "e.g. https://example.com/report.html",
        };

      default:
        return {
          label: "Unknown",
          placeholder: "Unknown",
        };
    }
  }

  return (
    <div
      className={classNames([
        "c-card",
        "c-card--link",
        "c-card--bg-white",
        "c-accordion",
        { "is-open": isOpen, "is-selected": isSelected },
      ])}
    >
      <div className="c-card__body">
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="c-card__header c-accordion__header"
        >
          <div className="c-accordion__toggle c-accordion__toggle--high"></div>
          <p className="c-card__headline">{name ? name : "New Dashboard"}</p>
          <div className="c-actions c-actions--divider-last">
            <div className="c-actions__col">{dashboardLink()}</div>
            <div className="c-actions__col">
              <ReorderHandle />
            </div>
            <div className="c-actions__col">
              <div
                onClick={(e) => {
                  setIsOpen(true);
                  setIsDeleting(true);
                  e.stopPropagation();
                }}
                className="c-link-cta-light"
              >
                <IconTrash className="o-svg-icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="c-accordion__container">
          <InputField
            type={InputFieldType.text}
            name={`dashboards[${sortIndex}].name`}
            placeholder="Menu Label"
            label="Menu Label"
            variant={FormFieldVariant.fill}
            autoFocus={true}
          />

          <hr className="c-hr--dashed" />
          <div className="o-row o-row--small-gutters">
            <div className="o-col-6@sm">
              <SelectField
                name={`dashboards[${sortIndex}].type`}
                placeholder="Content Type"
                label="Content Type"
                variant={FormFieldVariant.fill}
                valueTransform={(value) => {
                  return parseInt(value, 10);
                }}
              >
                <option value={DashboardType.adreport}>Ad Report</option>
                <option value={DashboardType.url}>URL</option>
              </SelectField>
            </div>
            <div className="o-col-6@sm">
              {type === DashboardType.adreport ? (
                <SelectField
                  name={`dashboards[${sortIndex}].source`}
                  placeholder={sourceLabel().placeholder}
                  label={sourceLabel().label}
                  variant={FormFieldVariant.fill}
                >
                  {adReportingClients.map((client, index) => (
                    <option key={index} value={client}>
                      {client}
                    </option>
                  ))}
                </SelectField>
              ) : (
                <InputField
                  type={InputFieldType.text}
                  name={`dashboards[${sortIndex}].source`}
                  placeholder={sourceLabel().placeholder}
                  label={sourceLabel().label}
                  variant={FormFieldVariant.fill}
                />
              )}
            </div>
          </div>
          <DeleteConfirmationAlert
            onDelete={() => {
              deleteHandler();
              setIsDeleting(false);
              setIsOpen(false);
            }}
            onCancel={() => {
              setIsDeleting(false);
            }}
            resource_label={name}
            show={isDeleting}
            type={DeleteConfirmationAlertType.Card}
          />
        </div>
      </div>
    </div>
  );
}

const SortableDashboardDetails = SortableElement((dashboard: iDashboardDetailsProps) => (
  <DashboardDetails {...dashboard} />
));
const SortableList = SortableContainer(({ children }: { children: React.ReactNode }) => {
  return <div>{children}</div>;
});
const ReorderHandle = SortableHandle(() => (
  <div className="c-link-cta-light">
    <IconMove className="o-svg-icon" />
    <span className="u-hidden u-block@sm">Reorder</span>
  </div>
));

interface iNoDashboardsPlaceholderProps {
  helpers: FieldArrayRenderProps;
}

function NoDashboardsPlaceholder({ helpers }: iNoDashboardsPlaceholderProps) {
  return (
    <div className="c-add c-add--link u-flex-nowrap@md">
      <div className="c-add__icon">
        <IconAddItem className="o-svg-icon" />
      </div>
      <div className="c-add__body">
        <div className="c-add__title">
          <div
            onClick={(event) => helpers.push(newDashboardObj())}
            className="c-link-cta c-add__link"
          >
            <span>Add new dashboard</span>
          </div>
        </div>
        <div className="c-add__desc">
          <p>You currently don’t have any dashboards added, click here to start.</p>
        </div>
      </div>
    </div>
  );
}

export function DashboardsTabSkeleton() {
  return (
    <div className="o-row">
      <div className="o-col-8@md">
        <div className="u-mb-spacer-base-large">
          <h6>
            <Skeleton width={250} />
          </h6>
          <Skeleton height={80} count={3} />
        </div>
        <div className="u-mb-spacer-base-large">
          <Skeleton height={50} width={300} />
        </div>
      </div>
      <div className="o-col-4@md">
        <div className="c-card c-card--bg-blue">
          <div className="c-card__body">
            <div className="c-card__header">
              <h6>
                <Skeleton width={250} />
              </h6>
              <div className="c-card__desc">
                <p>
                  <Skeleton count={3} />
                </p>
              </div>
            </div>
            <div className="o-row o-row--fluid c-button-group">
              <div className="o-col">
                <Skeleton height={50} />
              </div>
              <div className="o-col c-button-group__inline">
                <Skeleton height={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
