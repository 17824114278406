const config = {
  APP_NAME: 'Reportiv',
  API_BASE: process.env.REACT_APP_PUBLIC_API_BASE || 'https://localhost/api/v1',
  API_CLIENT_ID: process.env.REACT_APP_API_CLIENT_ID || '1',
  API_CLIENT_SECRET: process.env.REACT_APP_API_CLIENT_SECRET || 'yy7cZZkCfXYXAv953uW4LR5SaKcyN4NoxTpDcd6t',
  COOKIE_ACCESS_TOKEN_KEY: process.env.REACT_APP_COOKIE_ACCESS_TOKEN_KEY || 'reportiv_access',
  COOKIE_REFRESH_TOKEN_KEY: process.env.REACT_APP_COOKIE_REFRESH_TOKEN_KEY || 'reportiv_refresh',
  DEBUG: process.env.REACT_APP_DEBUG || false,
  CUBEJS_API_URL: process.env.REACT_APP_CUBEJS_API_URL || 'https://localhost:4443/cubejs-api/v1',
};

export default config;
