import * as React from 'react';

interface IRowProps {
  children: React.ReactNode;
}

const Row: React.FunctionComponent<IRowProps> = ({ children }) => {
  return (
    <div className="o-row o-row--small-gutters">
      {children}
    </div>
  );
};

export default Row;
